import React from 'react';

import { WLListItem, WLOrderedList, WLText } from '../../../../design-library';

const Article4: React.FC = () => (
  <WLText>
    Purposes and Objectives The purposes and objectives of WoodmenLife shall be:
    <WLOrderedList listStyleType="lower-alpha">
      <WLListItem>
        To seek as members individuals who desire to join together for mutual
        benefit and protection and, through our lodge system, based on chapters,
        to establish and implement programs to benefit our communities and our
        Nation as a whole through organized volunteer efforts. Members of
        WoodmenLife share common values and work to achieve shared fraternal
        ideals through their family, civic, charitable and patriotic activities.
        WoodmenLife shall engage in and support activity consistent with these
        fraternal ideals. Through a commitment to family, we become more
        dedicated family members; through volunteerism, we strengthen
        communities by assisting others and organizations which help others;
        through patriotic allegiance, we pledge to be better and more dedicated
        citizens; and through our chapters, we dedicate ourselves as volunteers
        to strengthen our families, our communities and our Nation;
      </WLListItem>
      <WLListItem>
        To issue to its members, either with or without medical examination,
        certificates providing for such benefits as may be authorized by the
        Constitution and Laws and in accordance with the laws governing
        fraternal benefit societies; and
      </WLListItem>
      <WLListItem>
        To engage in any and all other business allowed by law, consistent with
        the above purposes and objectives.
      </WLListItem>
    </WLOrderedList>
  </WLText>
);

export default Article4;
