import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article5: React.FC = () => (
  <>
    <WLH4>Chapters</WLH4>
    <WLText>
      <b>Sec. 1. Chapters and Members Thereof. </b>
      <br />
      WoodmenLife acts on a lodge system based on chapters. The term
      &ldquo;chapter&rdquo; shall include family, adult, youth, and virtual
      and/or digital chapters. In addition, chapters may be further defined as
      subordinate bodies of WoodmenLife as stated in Article 8 of
      WoodmenLife&rsquo;s Articles of Incorporation. Based on availability,
      members have the option of joining either a family chapter consisting of
      adult and youth members or a separate adult or youth chapter. Adult
      chapters can either operate as a family chapter or elect to maintain
      separate adult and youth chapters. Chapters shall exist only by the
      issuance of a charter signed by the President &amp; CEO and Secretary of
      WoodmenLife. All benefit and social members of WoodmenLife shall be
      members of the chapter into which they are assigned, or into which they
      are transferred, subject to Articles 4 and 7 of the Articles of
      Incorporation.
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Youth Chapters.</b> Should a separate youth chapter be chartered,
          such youth chapter shall be composed of the benefit members of
          WoodmenLife who have not attained the age of sixteen years. They may
          be divided and given such names or designations as shall seem
          appropriate to the National Board of Directors.
          <br />
          Every chartered youth chapter, with the permission of the President
          &amp; CEO of WoodmenLife, may be attached to and shall become the
          responsibility of an adult chapter. Youth members not of sufficient
          number to be chartered may be assigned to a family or adult chapter or
          transferred into another youth chapter, as the President &amp; CEO
          shall determine. When so assigned, the Chapter Secretary and
          Treasurer, as the case may be, shall serve the youth chapter and/or
          members in the same capacity. Funds of youth chapters shall be handled
          in the same manner as funds of adult chapters, except that the
          Treasurer shall keep a separate account appropriately named to reveal
          its ownership. No funds of a youth chapter shall be used for any
          purpose other than a youth program sanctioned by WoodmenLife.
          <br />
          A program of youth activities and rules and regulations for their
          government, including officers thereof, shall be promulgated and
          prescribed by the President &amp; CEO of WoodmenLife subject to the
          approval of the National Board of Directors and consistent with this
          Constitution and Laws.
          <br />
          The presiding officer of each family or adult chapter to which any
          youth chapter and/or youth members are attached shall appoint a
          Program Director to direct the same in their activities, subject to
          the approval of the President &amp; CEO of WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>Transfer of Youth Members to Chapters.</b> All youth members of
          WoodmenLife, upon attaining the age of sixteen years, shall be
          transferred to the chapter to which they or their youth chapter is
          attached, or, if not so attached, to the nearest family or adult
          chapter unless they shall request to be transferred elsewhere, subject
          to the provisions of this Constitution and Laws. The Secretary of
          WoodmenLife, or designee thereof, shall keep such records as are
          necessary to accomplish the foregoing.
        </WLListItem>
        <WLListItem>
          <b>
            Supervisory Power of National Convention or National Board of
            Directors Over All Chapters.
          </b>{' '}
          The National Convention or the National Board of Directors may
          prescribe rules and regulations to permit and facilitate any or all of
          the following:
          <WLOrderedList listStyleType="decimal">
            <WLListItem>The consolidation or merger of Chapters;</WLListItem>
            <WLListItem>
              Joint meetings of adult members under a common ritual;
            </WLListItem>
            <WLListItem>
              The organizing, creating and establishing, on a local basis within
              Jurisdictions, of District Meetings; and/or
            </WLListItem>
            <WLListItem>The orderly operations of Chapters.</WLListItem>
          </WLOrderedList>
        </WLListItem>
      </WLOrderedList>
    </WLText>

    <WLText>
      <b>Sec. 2. New Chapters and Youth Chapters. How Chartered.</b> New family,
      adult and youth chapters may be chartered when at least twenty
      Good-Standing Benefit Members are secured. No charter shall be issued to
      youth chapters until at least twelve of the required number have attained
      the age of eight years. Upon attaining charter strength, a charter and all
      necessary supplies shall be forwarded by the Secretary of WoodmenLife, or
      designee thereof, to all new family, adult and youth chapters.
    </WLText>

    <WLText>
      <b>Sec. 3. Powers and Duties of Chapters.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Power and Limitations.</b> Chapters shall have only such powers as
          are given them by this Constitution and Laws. The bylaws prescribed
          for their government and this Constitution and Laws shall govern them
          in all their transactions.
        </WLListItem>
        <WLListItem>
          <b>Duties. </b>Every chapter shall receive all members using the
          prescribed ritual of introduction. They shall not deviate from the
          prescribed ceremony of introduction.
        </WLListItem>
        <WLListItem>
          <b>Additional Limitations.</b> No chapter shall organize any
          corporation, association or other entity for the purpose of holding
          title to or managing any of its property without prior written
          approval of the President &amp; CEO and Secretary of WoodmenLife and
          on such terms and conditions as each may prescribe. Any such entity
          now in existence shall hold and/or manage all such property subject to
          this Constitution and Laws and for the sole use and benefit of the
          chapter to which it belongs, beneficially or otherwise. All such
          entities holding legal title to any such property shall also account
          to the President &amp; CEO of WoodmenLife as and when he/she shall
          direct and shall conform to his/her directives, even to the extent of
          dissolution and liquidation.
        </WLListItem>
      </WLOrderedList>
    </WLText>

    <WLText>
      <b>
        Sec. 4. Officers of Chapters, Their Duties, Meetings, and Quorum
        Requirements of Chapters.
      </b>{' '}
      All provisions relating to chapter officers, their duties, meetings and
      quorum requirements are contained in the Chapter Bylaws which are attached
      to this Constitution and Laws and which may be modified by the National
      Convention or the National Board of Directors.
    </WLText>

    <WLText>
      <b>Sec. 5. Election and Qualification of Jurisdictional Delegates.</b>
      <br />
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          At any regular meeting prior to January 31, immediately preceding each
          regular session of the Jurisdictional Convention, Jurisdictional
          Convention Delegates and a like number of alternates shall be elected
          thereto in the same manner as the officers of Chapters, and the
          Chapter Secretary shall immediately certify the same to the Secretary
          of the Jurisdiction and to the Secretary of WoodmenLife. The Secretary
          of WoodmenLife, or designee thereof, shall determine if all persons so
          elected are eligible to serve, based on guidelines established in
          WoodmenLife&rsquo;s Chapter Bylaws, and certify them to the
          Jurisdiction by the Secretary of WoodmenLife. If any such person is
          found to be ineligible to serve, the Secretary of WoodmenLife, or
          designee thereof, shall immediately notify the chapter, which may
          elect another Jurisdictional Convention Delegate or alternate. The
          Secretary of WoodmenLife shall not certify the eligibility of any
          person whose notice of election is received later than March 1.
        </WLListItem>
        <WLListItem>
          Each chapter located within the territorial limits of each
          Jurisdiction and which has not fewer than twenty beneficiary members
          shall be entitled to representation in the Jurisdictional Convention
          according to its membership as follows: one Jurisdictional Convention
          Delegate for twenty benefit members; one Jurisdictional Convention
          Delegate for each additional 100 benefit members up to 500 additional
          members; one Jurisdictional Convention Delegate for each additional
          200 benefit members between 520 members and 920 additional benefit
          members; and thereafter, one Jurisdictional Convention Delegate for
          each additional 300 benefit members.
          <br />
          The number of such Jurisdictional Convention Delegates to which any
          chapter shall be entitled shall be determined by the records of the
          Secretary of WoodmenLife as of November 30 immediately preceding the
          election thereof. Alternate Jurisdictional Convention Delegates to
          Jurisdictional Conventions shall have the same rights and privileges
          as their principals, in the absence of the latter.
          <br />
          The charters of eligible Chapters failing to elect Jurisdictional
          Convention Delegates and alternates shall stand suspended. Failure to
          so elect Jurisdictional Convention Delegates and alternates to two
          consecutive Jurisdictional Conventions may result in a forfeiture of
          chapter charters, and the President of WoodmenLife may consolidate
          said chapter as provided in this Article 5.
        </WLListItem>
      </WLOrderedList>
    </WLText>

    <WLText>
      <b>Sec. 6. Consolidation and Merger.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          The President &amp; CEO of WoodmenLife, in his/her discretion, may
          consolidate or merge two or more family, adult or youth chapters
          whenever he/she deems it to be in the best interest of WoodmenLife, or
          he/she may, under like circumstances, transfer all the members thereof
          to any other such subordinate body, provided satisfactory provision is
          made for the payment of all liabilities of all such bodies dissolved
          and consolidated or merged or whose members are transferred to another
          such body. The President &amp; CEO of WoodmenLife shall also have
          authority, in his discretion, to transfer only part of the members of
          any such body to another appropriate subordinate body without regard
          to the liabilities of the body from which they are transferred.
        </WLListItem>
        <WLListItem>
          The records, paraphernalia, badges and all other property, real,
          personal, and mixed, belonging to any family, adult or youth chapter
          dissolved, consolidated or merged, shall become the property of the
          subordinate body with which it is consolidated or merged except any
          specific property the President &amp; CEO requests to be forwarded to
          WoodmenLife. The officers of the chapter which has been dissolved,
          consolidated or merged shall execute all deeds, bills of sale and
          other documents that may be necessary or required to affect any such
          transfer of property.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 7. Suspension, Revocation and Reinstatement of Charters.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          Upon the suspension or revocation of the charter of any family, adult
          or youth chapter, the officers thereof shall deliver to the President
          &amp; CEO of WoodmenLife, or his/her duly authorized representative,
          the charter thereof, seal, rituals, records, funds, and all property
          of whatever kind or nature, title to which shall immediately vest in
          WoodmenLife. All members of such family, adult or youth chapter shall
          be immediately transferred by the Secretary of WoodmenLife, or
          designee thereof, to some other appropriate family, adult or youth
          chapter when the charter thereof has been revoked.
        </WLListItem>
        <WLListItem>
          Upon the reinstatement of any suspended charter, all property not
          disposed of and the proceeds of all property disposed of, together
          with the charter and records taken at the time of such suspension,
          shall be restored.
        </WLListItem>
        <WLListItem>
          Funds belonging to family, adult and youth chapters whose charters
          have been suspended shall be held by WoodmenLife until their charters
          are restored. If said charters are not restored, but are finally
          revoked, the funds shall be paid to those family, adult or youth
          chapters to which the members are transferred in proportion to the
          number of such members being transferred.
        </WLListItem>
      </WLOrderedList>
    </WLText>
  </>
);

export default Article5;
