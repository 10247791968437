import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article3: React.FC = () => (
  <>
    <WLH4>Officers, Election and Installation</WLH4>
    <WLText>
      <b>Sec. 1. Jurisdictional Convention Officers and Their Duties.</b>
      <br />
      The Jurisdictional Convention Officers shall be the following: President,
      Immediate Past President, Vice President, Secretary, Treasurer, and a
      minimum of three and maximum of five Auditors. The Jurisdiction may also
      have the following optional officers: Vice President, Community Outreach;
      Vice President, Youth Outreach; Vice President, Membership; and Vice
      President, Publicity. Jurisdictional Convention Officers shall be elected
      and serve for four years and until their successors are elected and
      qualified. The Past President shall attain his/her office by virtue of
      having previously served as President.
    </WLText>
    <WLText>
      (a) <b>Past President.</b> The Immediate Past President shall support the
      Convention Officers in their work toward advancing the mission and vision
      of WoodmenLife. In the event the President and Vice President are unable
      to assume the responsibilities of the office of President, the immediate
      Past President shall assume those duties until the vacancies are filled
      under the election procedure described in Article 3, Section 3 of these
      Bylaws.
    </WLText>
    <WLText>
      (b) <b>President.</b> The President shall preside at all sessions of the
      Jurisdictional Convention; be the judge of elections and declare the
      results thereof; except as otherwise set forth in these Bylaws, appoint
      all committees necessary to expedite the business of the Jurisdictional
      Convention; fill vacancies in offices by appointment until an election is
      held under the election procedure described in Article 3, Section 3 of
      these Bylaws; sign all documents issued by order of the Jurisdictional
      Convention; cooperate with the President &amp; CEO of WoodmenLife to
      further the interests of WoodmenLife within the geographic area of the
      Jurisdiction; make a report to every session pertaining to the business of
      the Jurisdiction, together with such recommendations as he/she may deem
      advisable; and perform such other duties as may be required by
      WoodmenLife&rsquo;s Constitution and Laws or these Bylaws.
    </WLText>
    <WLText>
      (c) <b>Vice President.</b> The Vice President shall assist the President
      by assuming responsibility for programs, projects, initiatives, or other
      assignments that may be made by the President and, in the absence of the
      President, shall assume his/her duties.
    </WLText>
    <WLText>
      (d) <b>Secretary.</b> The Secretary shall have charge of the records of
      the Jurisdiction and keep the minutes of its meetings; attend to
      Jurisdictional correspondence; receive all funds due the Jurisdiction and
      pay the same immediately to the Treasurer; and perform such other duties
      as may be required by the Jurisdiction or by the President &amp; CEO of
      WoodmenLife. The Secretary shall also, within ten days after the
      adjournment of every session of the Jurisdiction, forward to the Secretary
      of WoodmenLife a copy of the recorded proceedings of the Jurisdiction,
      duly signed by the Secretary and the President of the Jurisdiction,
      together with a duplicate certificate of the Jurisdictional Convention
      Representatives thereof elected to all offices, including National
      Convention Delegates and their alternates when appropriate, and a copy of
      all resolutions and recommendations adopted by the Jurisdictional
      Convention.
    </WLText>
    <WLText>
      (e) <b>Treasurer.</b> The Treasurer shall be the custodian of the funds of
      the Jurisdiction, which shall be deposited into a secure depository
      designated by, and to the sole credit of, the Jurisdiction; shall pay all
      expenses as approved by the Secretary and attested by the President; shall
      keep a correct record and complete account of all receipts and
      disbursements; and shall make a detailed report to each session of the
      Jurisdictional Convention regarding his/her transactions and furnish a
      copy thereof to the President, Secretary and Chairperson of Auditors of
      the Jurisdiction.
    </WLText>
    <WLText>
      (f)<b> Auditors.</b> The Auditors of the Jurisdiction and their successors
      in office shall examine all claims upon the Jurisdiction; audit the books
      of the Secretary and Treasurer at each regular session of the
      Jurisdictional Convention; and make a full report thereon to the
      Jurisdictional Convention, copies of which they shall furnish to the
      President, Secretary and Treasurer of the Jurisdiction. Jurisdictional
      Conventions shall elect one of the Auditors as Chairperson.
    </WLText>
    <WLText>
      <b>(Optional Officers)</b>
    </WLText>
    <WLText>
      (g) <b>Vice President, Community Outreach.</b> The Vice President,
      Community Outreach shall monitor the community outreach activities and
      events as scheduled by the chapters within the Jurisdiction and assist in
      coordinating such activities and events if conducted by the Jurisdiction
      with the Jurisdictional Convention Representatives/ committees responsible
      for organizing such activities or events. The Vice President, Community
      Outreach should also encourage the chapters in the Jurisdiction to
      develop/maintain positive relationships with community partners and
      conduct an annual calendar planning session for each chapter to set the
      schedule for future community outreach activities and events.
    </WLText>
    <WLText>
      (h) <b>Vice President, Youth Outreach.</b> The Vice President, Youth
      Outreach shall monitor youth activities and events as scheduled by the
      chapters in the Jurisdiction as well as assist in coordinating such
      activities and events if conducted by the Jurisdiction. The Vice
      President, Youth Outreach should also encourage the chapters within the
      Jurisdiction to develop/maintain positive relationships with various youth
      organizations and participate in an annual calendar planning session for
      each chapter to schedule youth activities and events.
    </WLText>
    <WLText>
      (i) <b>Vice President, Membership.</b> The Vice President, Membership
      shall monitor new member outreach activities and events as scheduled by
      chapters and shall encourage members to attend these new member outreach
      activities and events. The Vice President, Membership should also
      encourage chapters to introduce new members and coordinate with
      WoodmenLife&rsquo;s Sales associates to develop referral lists and invite
      prospective members to chapter activities and social events.
    </WLText>
    <WLText>
      (j) <b>Vice President, Publicity.</b> The Vice President, Publicity shall
      coordinate with the Jurisdictional President and other Jurisdictional
      Convention Officers, Jurisdictional committees and the Home Office to
      publicize activities and events within the Jurisdiction. This officer
      should also work closely with chapters within the Jurisdiction and
      WoodmenLife&rsquo;s Sales associates to bring greater awareness of
      WoodmenLife within the geographic area of the Jurisdiction.
    </WLText>

    <WLText>
      <b>
        Sec. 2. Jurisdictional Fraternal Committee, Election, Term of Office,
        Report to the Jurisdiction; Vacancy.
      </b>
      <br />
      (a) The Jurisdictional Fraternal Committee shall be composed of a
      chairperson and not fewer than two or more than four additional members
      and shall be elected by each Jurisdiction in the same manner and for the
      same term as the officers of the Jurisdiction.
    </WLText>
    <WLText>
      (b) The members of the Jurisdictional Fraternal Committee shall be members
      of the Jurisdictional Convention with all rights and privileges of any
      other member.
    </WLText>
    <WLText>
      (c) The committee shall have the responsibility of promoting fraternalism
      within the Jurisdiction and of preparing reports and making
      recommendations concerning the advancement of WoodmenLife&rsquo;s
      fraternal endeavors. The committee shall coordinate the Jurisdictional
      Fraternal Program with the National Program through the Office of the
      Secretary of WoodmenLife, and the committee chairperson shall make a
      report to each session of the Jurisdiction.
    </WLText>
    <WLText>
      (d) Any vacancy existing on the committee may be filled by the
      Jurisdictional President.
    </WLText>
    <WLText>
      <b>
        Sec. 3. Election and Installation of Jurisdictional Convention Officers.
      </b>
      <br />
      (a) A procedure for nominating a Jurisdictional Convention Representative
      to serve as a Jurisdictional Convention Officer shall be established by
      the Jurisdiction and may involve nominating such Jurisdictional Convention
      Representatives through a nominating committee or nominations made from
      the floor.
    </WLText>
    <WLText>
      (b) Once the report of the nominating committee is accepted and no
      additional nominations are received or, in the case of nominations from
      the floor, all nominations are received and the process is closed,
      Jurisdictional Convention Officers shall be elected by a majority of all
      Jurisdictional Convention Representatives present at each quadrennial
      session of the Jurisdiction. The Jurisdictional Convention Officers so
      elected shall be installed during the same meeting of the Jurisdiction in
      which they were elected.
    </WLText>
    <WLText>
      (c) Any National Director who is present may preside at the installation
      of all incoming Jurisdictional Convention Officers. In the event that no
      National Director is present, the Immediate Past President shall preside
      at the installation or shall appoint any other Jurisdictional Convention
      Representative to perform that duty. All Jurisdictional Convention
      Officers may be jointly or severally installed.
    </WLText>
    <WLText>
      <b>Sec. 4. Qualifications of Jurisdictional Convention Officers.</b>
      <br />
      (a) Any Good Standing Benefit Member of WoodmenLife who resides in the
      Jurisdiction and is a Jurisdictional Convention Representative as
      described in Article 2, Section 1 of these Bylaws is qualified to serve as
      a Jurisdictional Convention Officer, except that no such Jurisdictional
      Convention Representative who is, becomes during his/her tenure in office,
      or has been within the year immediately preceding, an agent of, employed
      by, independently contracted with, or in any other manner selling or
      promoting products of another fraternal benefit society or life insuring
      institution unless such sales and products are authorized by WoodmenLife,
      may serve as a Jurisdictional Convention Officer or a National Convention
      Representative. If a Jurisdictional Convention Representative becomes
      disqualified during his/her tenure in office, his/her position shall
      immediately become vacant and the Jurisdictional Convention President
      shall appoint an individual to fill such office until an election is held.
    </WLText>
    <WLText>
      (b) No person who has retired as an officer, member of the National Board
      of Directors, employee, or Sales Representative of WoodmenLife, and is
      drawing retirement or disability benefits from WoodmenLife, may be elected
      as the Jurisdictional President or as a National Convention Delegate or
      alternate thereto.
    </WLText>
    <WLText>
      (c) No person, including an alternate National Convention Delegate, may
      serve in two Jurisdictional offices at the same time. If a Jurisdictional
      Convention Officer or National Convention Delegate accepts appointment to
      an additional office, he/she shall automatically vacate the first office
      held.
    </WLText>
    <WLText>
      <b>Sec. 5. Officers to Report to the Jurisdictional Convention.</b>
      <br />
      All Jurisdictional Convention Officers and National Convention Delegates
      shall make written report to the Jurisdictional Conventions, reporting
      their activities on behalf of WoodmenLife since assuming their offices.
    </WLText>
    <WLText>
      <b>Sec. 6. National Convention Delegates and Alternates.</b>
      <br />
      National Convention Delegates and alternates thereto shall be elected at
      all regular sessions of the Jurisdiction held in the same year as
      WoodmenLife&rsquo;s National Convention in the manner described in Article
      3, Section 3 of these Bylaws.
    </WLText>
    <WLText>
      <b>Sec. 7. Bonds of Jurisdictional Convention Officers.</b>
      <br />
      WoodmenLife&rsquo;s National Board of Directors and/or Jurisdictional
      Convention Representatives may require certain Jurisdictional Convention
      Officers to furnish a bond to insure the faithful performance of their
      duties, the same to be in such forms and sums as they shall determine.
      Bonds required by the National Board of Directors shall be paid for by
      WoodmenLife, and those required by the Jurisdictional Convention
      Representatives shall be paid for by the Jurisdiction. A blanket bond,
      instead of individual bonds, may be authorized.
    </WLText>
    <WLText>
      <b>Sec. 8. Consecutive Terms.</b>
      <br />
      In order to offer greater opportunity for service by all, and especially
      to newer members of WoodmenLife, no elected Jurisdictional Convention
      Officer other than the Secretary or Treasurer shall serve consecutive
      terms in the same office, unless there are special reasons for making an
      exception to the rule. Whether to make an exception shall be determined by
      a two-thirds vote of the members present at either a regular or special
      meeting of the Jurisdiction.
    </WLText>
    <WLText>
      <b>Sec. 9. Duties at End of Term of Office.</b>
      <br />
      Every Jurisdictional Convention Officer shall, at the expiration of
      his/her term of office, deliver to the successor in office all funds,
      securities, property, records, vouchers, seals, and papers of every kind
      pertaining to the affairs of the Jurisdiction.
    </WLText>
    <WLText>
      <b>Sec. 10. Removal.</b>
      <br />
      Removal from office of any Jurisdictional Convention Officer shall
      automatically occur when any Jurisdictional Convention Officer is found to
      have violated any insurance statute or regulation, any securities
      regulation or any federal or state criminal law that would preclude
      employment with WoodmenLife or who becomes, or has been within the year
      immediately preceding, an agent of, employed by, independently contracted
      with, or in any other manner sold or promoted products of another
      fraternal benefit society or life insuring institution unless such sales
      and products are authorized by WoodmenLife. Removal from office of any
      Jurisdictional Convention Officer may also occur upon the complaint of any
      Jurisdictional Convention Representative to the President of the
      Jurisdiction after ten days&rsquo; written notice by the Secretary to each
      Jurisdictional Convention Representative and a vote of two-thirds of the
      Jurisdictional Convention Representatives present at any regular meeting
      of the Jurisdiction or at a special meeting called for that purpose,
      provided that there shall be at least twelve Jurisdictional Convention
      Representatives in attendance and the accused Jurisdictional Convention
      Officer shall have been notified in writing by the Jurisdictional
      Secretary to appear and show cause why he/she should not be removed. If
      any Jurisdictional Convention Representative seeks to remove the
      Jurisdictional President from office, complaint shall be made to the
      Jurisdictional Vice President, who shall cause the Secretary to send the
      notices described above.
    </WLText>
    <WLText>
      <b>Sec. 11. Vacancies.</b>
      <br />
      (a) The President of the Jurisdiction shall have the right to appoint a
      Jurisdictional Convention Officer to fill a vacancy until such time as the
      Jurisdictional Convention Officers shall elect a new officer. In the event
      the office of President should become vacant, either through removal,
      resignation or death, the Vice President shall assume the position of
      President and shall appoint a Jurisdictional Convention Officer to the
      office of Vice President until such time as the Jurisdictional Convention
      Officers shall elect a new Vice President. In the event that both the
      offices of President and Vice President shall become vacant at the same
      time, the Immediate Past Jurisdictional President shall serve in the
      office of President and shall appoint a Vice President, each of whom shall
      serve in such offices until such time as the election is held and the new
      Jurisdictional Convention Officers are installed. In the event the
      Immediate Past Jurisdictional President is unable to serve as
      Jurisdictional President, a preceding Past Jurisdictional President, in
      order of he/she who most recently served in such position, shall serve in
      his/her stead.
    </WLText>
    <WLText>
      (b) When any Jurisdictional Convention Officer or National Convention
      Delegate from any Jurisdiction and/or his/her alternate moves from the
      geographic area of his/her Jurisdiction for any reason, his/her office and
      membership therein shall immediately become vacant, except in the case
      where he/she resides in an adjoining state and continues to be active in
      the Jurisdiction he/she represents.
    </WLText>
    <WLText>
      (c) If the Chapter to which any Jurisdictional Convention Officer or
      National Convention Delegate from a Jurisdiction belongs shall have less
      than twenty members in good standing on December 31 immediately preceding
      the session of the Jurisdictional Convention, his/her office therein shall
      immediately become vacated, and all rights and privileges thereof shall
      cease.
    </WLText>
    <WLText>
      <b>Sec. 12. Compensation.</b>
      <br />
      No compensation shall be paid to the Jurisdictional Convention Officers.
      Nothing in these Bylaws, however, shall preclude the payment of reasonable
      expenses incurred in the legitimate conduct of Jurisdictional business.
    </WLText>
  </>
);

export default Article3;
