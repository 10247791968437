import React from 'react';

import {
  WLH4,
  WLListItem,
  WLText,
  WLUnorderedList,
} from '../../../../design-library';

const Article1: React.FC = () => (
  <>
    <WLH4>Name and Purpose</WLH4>
    <WLText>
      <b>Sec. 1. Name.</b>
      <br />
      The name of this chapter shall be _______________ Chapter No.
      _______________ (if chapter), located in _______________, State of
      _______________.
    </WLText>
    <WLText>
      <b>Sec. 2. Members. </b>
      <br />
      This chapter shall be composed of its adult and youth members (adult
      members only if the chapter maintains a separate youth chapter).
    </WLText>
    <br />
    <WLText>
      <b>Sec. 3. Purposes.</b>
      <br />
      This chapter is a subordinate body of Woodmen of the World Life Insurance
      Society (&ldquo;WoodmenLife&ldquo;), a fraternal benefit society that
      exists for the exclusive benefit of its members and operates under a lodge
      system based on local chapters. The chapter serves four essential
      purposes:
    </WLText>
    <WLText>
      (a) The chapter and its members form a part of the system that is the
      foundation through which WoodmenLife&apos;s representative form of
      government is structured and operates;
    </WLText>
    <WLText>
      (b) The chapter represents WoodmenLife in the community by fostering and
      communicating WoodmenLife&apos;s:
    </WLText>
    <WLUnorderedList>
      <WLListItem>
        <b>Common Bond:</b> As members of WoodmenLife, we share a commitment to
        family, community and country. Through a commitment to family, we become
        more dedicated family members. Through volunteerism, we strive to be
        better friends and neighbors. Through our patriotic allegiance, we
        pledge to be better and more dedicated citizens.
      </WLListItem>
      <WLListItem>
        <b>Mission:</b> Uniting hardworking Americans to secure their financial
        future while strengthening our communities and country.
      </WLListItem>
      <WLListItem>
        <b>Values:</b> At WoodmenLife, we value:
        <WLUnorderedList listStyleType="square">
          <WLListItem>
            <WLText>
              <em>PASSION FOR MEMBERS: </em>
              We act in the best interest of our members at all times, providing
              them with support and superior service. We own our actions and
              strive to make a difference for our members and their communities.
              We operate with speed, integrity and commitment.
            </WLText>
          </WLListItem>
          <WLListItem>
            <WLText>
              <em>SERVICE TO OTHERS: </em>
              We care deeply about our communities and our country. We give back
              to our members and the places they call home. We are there for
              people when they need us most.
            </WLText>
          </WLListItem>
          <WLListItem>
            <WLText>
              <em>INCLUSION: </em>
              We value our differences and respect what each of us brings to the
              table. We are committed to diversity and to providing
              opportunities for growth, leadership and service to all.
            </WLText>
          </WLListItem>
          <WLListItem>
            <WLText>
              <em>COLLABORATIVE SPIRIT: </em>
              We drive results through collaboration and teamwork. We share a
              commitment to our goals and to each other throughout every level
              of the organization. We speak openly and have the courage to
              address issues to arrive at the best results for our members and
              our associates.
            </WLText>
          </WLListItem>
          <WLListItem>
            <WLText>
              <em>OPEN-MINDEDNESS: </em>
              We are open to new ideas and improved ways of doing things. We are
              solution-oriented and future-focused. We embrace innovation in our
              pursuit of growth.
            </WLText>
          </WLListItem>
        </WLUnorderedList>
      </WLListItem>
    </WLUnorderedList>
    <WLText>
      (c) The chapter develops and implements plans and initiatives (with
      specific action items and timelines) to engage in and support community
      outreach and fraternal activity consistent with WoodmenLife&apos;s Common
      Bond, Mission and Values; and
    </WLText>
    <WLText>
      (d) The chapter partners with all of WoodmenLife&apos;s associates,
      community organizations and community leaders to support community
      outreach and fraternal activity, increase membership and promote
      WoodmenLife&apos;s insurance and financial services products.
    </WLText>
  </>
);

export default Article1;
