import React from 'react';

import { WLText } from '../../../../design-library';

const Content: React.FC = () => (
  <>
    <WLText>
      <b>Sec. 1. The President &amp; CEO.</b>
      <br />
      The President &amp; CEO shall be the chief executive officer of
      WoodmenLife and shall have general supervision over the affairs of
      WoodmenLife. The President &amp; CEO shall:
    </WLText>
    <WLText>(a) be a member of the Executive and Investment Committees;</WLText>
    <WLText>
      (b) preside over all sessions of the National Convention and all meetings
      of the Executive and Investment Committees or designate someone to do so;
    </WLText>
    <WLText>
      (c) enter into written contracts with Sales associates for the
      solicitation of applications for membership under such terms as may be
      agreed upon, subject to the review of any such contracts by the National
      Board of Directors at its discretion;
    </WLText>
    <WLText>
      (d) prescribe and/or approve all forms and blanks and all other printed
      materials and publications issued or used by WoodmenLife or the National
      Convention and any of its departments, subdivisions, subordinate bodies,
      and associates, or cause the same to be done, and no officer, associate or
      member, or other persons, shall publish or use any such materials not so
      authorized or approved;
    </WLText>
    <WLText>
      (e) prescribe the forms of all benefit certificates of membership to be
      issued by WoodmenLife and prescribe all plans under which persons are
      admitted to membership in WoodmenLife, together with the rates of payments
      under any and all such certificates when such plans and amounts have not
      been theretofore prescribed by the National Convention or the National
      Board of Directors;
    </WLText>
    <WLText>
      (f) have authority to reinsure or coinsure WoodmenLife&rsquo;s liability,
      or any portion thereof, on any and all certificates of membership now
      outstanding and/or hereafter issued;
    </WLText>
    <WLText>
      (g) with the assistance and approval of the National Board of Directors,
      prepare and publish the rituals and ceremonials of WoodmenLife; be the
      custodian of and instructor in all the rituals thereof; and promulgate to
      all chapters the password to be used in conjunction with the historic
      ritual;
    </WLText>
    <WLText>
      (h) examine, or cause to be examined, every application for membership in
      WoodmenLife and every application for any benefit and, if found to conform
      to all requirements for such, shall approve said applications or cause the
      same to be done;
    </WLText>
    <WLText>
      (i) subject to the limitations elsewhere contained in the Constitution and
      Laws, establish rules and rates with reference to applications on standard
      and substandard risks and, with respect thereto, shall classify all such
      risks as to physical and mental impairment, occupation, residence, habits,
      morals, environment, finances, previous experience and any other factors
      that may be deemed wise, and cause to be fixed such rules, rates and extra
      rates as may be required by customary underwriting practices;
    </WLText>
    <WLText>
      (j) grant written dispensations for any purpose except to admit a person
      to membership who is not physically or mentally qualified under the
      Constitution and Laws, provided that no authority is given to do anything
      that will impair the contract of a beneficiary member of WoodmenLife;
    </WLText>
    <WLText>
      (k) make a report on the state of affairs of WoodmenLife at each regular
      session of the National Convention;
    </WLText>
    <WLText>
      (l) sign all documents and other papers requiring his/her signature and
      customarily signed by the president of a corporation;
    </WLText>
    <WLText>
      (m) appoint committees from the Good Standing Benefit Members of
      WoodmenLife to assist in WoodmenLife&rsquo;s management, to be known as
      Presidential Committees, and which committees shall be responsible to and
      under the direction of the President &amp; CEO;
    </WLText>
    <WLText>
      (n) appoint persons to examine or audit the books, records and accounts of
      any subordinate body of WoodmenLife, or officers thereof, and may suspend
      or remove from office any officer of any subordinate body thereof when
      satisfactory information exists that any such officer is negligent of
      his/her duties or incompetent;
    </WLText>
    <WLText>
      (o) whenever the President &amp; CEO of WoodmenLife believes that the
      funds and/or property of any subordinate body are being or will be
      improperly diverted, disposed of, wasted, destroyed or distributed, he/she
      shall have full authority, in the name of WoodmenLife, to have such action
      enjoined and to seize such funds and/or property by action in court or
      otherwise, in order to preserve the same. He/She may render to the
      subordinate body concerned such report regarding any examination of its
      books or such other action herein authorized as he/she shall deem
      necessary; and
    </WLText>
    <WLText>
      (p) discipline and, in furtherance thereof, have power to suspend or
      revoke charters of subordinate bodies for:
    </WLText>
    <WLText>
      (1) refusing to conform to the Constitution and Laws and Bylaws and Rules
      of Order of Subordinate Bodies;
    </WLText>
    <WLText>
      (2) refusing to make reports required by WoodmenLife or any of its
      officers;
    </WLText>
    <WLText>
      (3) wrongfully withholding any funds or property belonging to WoodmenLife
      or any of its subordinate bodies;
    </WLText>
    <WLText>
      (4) disseminating communications or circulars derogatory to WoodmenLife or
      any of its officers;
    </WLText>
    <WLText>
      (5) soliciting contributions when not authorized by the President &amp;
      CEO of WoodmenLife except for typical fraternal and community activities;
    </WLText>
    <WLText>
      (6) instituting suits or other legal proceedings against WoodmenLife or
      any of its officers without first using the Dispute Resolution Procedures
      as outlined in Article 7 of the Constitution and Laws; or
    </WLText>
    <WLText>
      (7) failing or refusing to comply with or conform to any lawful demand by
      the President &amp; CEO or other officers of WoodmenLife.
    </WLText>
    <WLText>
      The members of any subordinate body whose charter has been suspended or
      revoked shall continue to make their required payments to the Home Office
      of WoodmenLife until the matter shall be finally determined in accordance
      with the Constitution and Laws.
    </WLText>
    <WLText>
      Any member of WoodmenLife who is found by the President &amp; CEO of
      WoodmenLife, after due inquiry, to be responsible for any of the foregoing
      acts shall be barred from a seat in any Jurisdictional or National
      Convention and, if occupying such an office, shall be removed; and if such
      member is an officer of a subordinate body of WoodmenLife, the President
      &amp; CEO of WoodmenLife may declare the office vacant.
    </WLText>
    <WLText>
      Any subordinate body, and any member thereof, aggrieved by any action
      herein authorized may appeal to the Judiciary Committee by giving written
      notice thereof to the Secretary of WoodmenLife within thirty days after
      notice of any such action. The matter shall be disposed of by the
      Judiciary Committee as provided in Article 8 of the Constitution and Laws;
    </WLText>
    <WLText>
      (q) appoint one or more approving officers who shall serve at the pleasure
      of the Executive Committee, and whose duty it shall be to examine and
      approve every claim against WoodmenLife payable from any of its funds,
      except in any benefit certificate, and no check shall be drawn unless and
      until approved by an approving officer (no such person shall have
      authority to approve his/her own claim against WoodmenLife);
    </WLText>
    <WLText>
      (r) have authority to designate individuals in addition to the Executive
      Officers authorized to execute contracts on behalf of WoodmenLife;
    </WLText>
    <WLText>
      (s) except as otherwise specified by the National Board of Directors
      within its constitutional authority, and not inconsistently with or
      repugnant to their duties and functions set forth and defined elsewhere in
      the Constitution and Laws, have authority to designate the title of any
      officer or other person employed in any capacity by WoodmenLife and to
      assign and reassign the duties of all Executive Officers, officers and
      associates of WoodmenLife;
    </WLText>
    <WLText>
      (t) make recommendation to the National Board of Directors for the
      appointment of a General Counsel and/or Chief Legal Officer, who shall
      serve at the pleasure of the Board and who shall be under the direct
      supervision of the President &amp; CEO and perform all duties assigned;
    </WLText>
    <WLText>
      (u) appoint three Executive Vice Presidents and any other additional
      Executive Vice President position approved by the National Board of
      Directors;
    </WLText>
    <WLText>
      (v) appoint a Medical Director, who shall be under the direct supervision
      of the Chief Underwriter and who shall perform all duties assigned;
    </WLText>
    <WLText>
      (w) administer the National Fraternal Program and report on same to the
      National Convention;
    </WLText>
    <WLText>
      (x) have the authority to suspend or remove chapter and/or Jurisdictional
      officers or members of any subordinate body of WoodmenLife when, in his
      opinion, good cause exists for such removal;
    </WLText>
    <WLText>
      (y) make recommendation to the National Board of Directors for the
      appointment of the Secretary of WoodmenLife; and
    </WLText>
    <WLText>
      (z) perform such other duties as are imposed upon him/her in the
      Constitution and Laws or as may be assigned to him/her by the National
      Convention and/or the National Board of Directors and which are not
      otherwise inconsistent with the Constitution and Laws.
    </WLText>
    <WLText>
      <b>Sec. 2. Secretary.</b>
      <br />
      The Secretary, or designee thereof as may be approved by the President and
      CEO, shall be the chief recording officer of WoodmenLife organizational
      documents and shall:
    </WLText>
    <WLText>
      (a) be responsible for and keep and maintain all the official
      organizational records of WoodmenLife, the National Convention, the
      National Board of Directors, the Executive and Investment Committees, and
      all other committees provided for herein, except such records as are
      specifically exempted and made the special trust of another officer under
      the Constitution and Laws;
    </WLText>
    <WLText>
      (b) at all sessions of the National Convention, meetings of the National
      Board of Directors and Standing Committees, all bills, communications and
      all other papers shall be read by him/her, or they shall be read by
      someone appointed by the President &amp; CEO, except reports of other
      committees, which may be read by the Chairman or any member thereof;
    </WLText>
    <WLText>
      (c) make a full and complete report of all his/her transactions to every
      regular session of the National Convention;
    </WLText>
    <WLText>
      (d) make full and complete reports, in the manner and time required by the
      several state insurance departments and all other government agencies
      having jurisdiction over any of the affairs of WoodmenLife;
    </WLText>
    <WLText>
      (e) sign all official documents of WoodmenLife requiring his/her
      signature, affixing thereto the seal of WoodmenLife when necessary; and
      he/she shall prepare and certify, under the seal of WoodmenLife, all
      copies of books, records and other documents of WoodmenLife, or portions
      thereof, which may be required for any purpose;
    </WLText>
    <WLText>
      (f) be the custodian of all the supplies, furniture, fixtures and other
      personal property belonging to WoodmenLife, excepting cash and
      investments, and shall dispose of same only upon proper orders and in
      accordance therewith, keeping a complete and accurate account of all such
      purchases, sales, re-sales and/or exchanges thereof;
    </WLText>
    <WLText>
      (g) in addition to the President &amp; CEO, shall sign all new
      certificates issued by WoodmenLife;
    </WLText>
    <WLText>
      (h) perform all other duties customarily performed by the Secretary of a
      corporation; and
    </WLText>
    <WLText>
      (i) perform all duties as required by the Constitution and Laws and such
      other duties as may be assigned by the President &amp; CEO.
    </WLText>
  </>
);

export default Content;
