import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article5: React.FC = () => (
  <>
    <WLH4>Jurisdiction Funds, Property and Audits</WLH4>
    <WLText>
      <b>Sec. 1. Jurisdictional Expenses.</b>
      <br />
      (a) Jurisdictions shall approve and pay those expenses associated with the
      Jurisdictional meetings and conducting the affairs of the Jurisdiction. As
      to reasonable expenses of Jurisdictional Convention Representatives or
      Jurisdictional Convention Officers, no claim shall be allowed by any
      Jurisdiction unless and until the same has been referred to, and approved
      by, the Treasurer or a committee of the Jurisdiction appointed for that
      purpose, and then only after such recommendation has been adopted, in
      whole or in part, by a vote of the Jurisdictional Convention
      Representatives.
    </WLText>
    <WLText>
      (b) Any Past President not residing within the territory of the
      Jurisdiction shall be limited to travel expenses from the chapter within
      the Jurisdiction in which he/she holds a membership to the site of the
      Jurisdictional meeting.
    </WLText>
    <WLText>
      (c) The reasonable expenses of Jurisdictional Convention Delegates to
      attend the Jurisdictional meetings, or any part of such expense, may be
      paid by the chapter represented by such Jurisdictional Convention
      Delegate.
    </WLText>
    <WLText>
      <b>Sec. 2. Member Proration.</b>
      <br />
      (a) In addition to all other sums, WoodmenLife shall pay a member
      proration to help defray the costs of holding each regular meeting of the
      Jurisdiction. The amount of the member proration shall be established by
      the National Board of Directors for each and every Good Standing Benefit
      Member credited to the subordinate bodies located within the geographic
      area of the Jurisdiction as shown on the records of the Secretary of
      WoodmenLife as of November 30 immediately preceding any regular meeting of
      the Jurisdiction.
    </WLText>
    <WLText>
      (b) Should the proration herein provided for, and contributed by
      WoodmenLife, exceed the expenses of the regular meeting of the
      Jurisdiction, the surplus funds may be used to pay, in whole or in part, a
      Jurisdictional Convention Delegate&rsquo;s reasonable expenses.
    </WLText>
    <WLText>
      <b>Sec. 3. Jurisdictional Funds and Property. </b>
      <br />
      The Treasurer shall not pay out, or contract to pay out, any sum of money,
      except for current expenses, and the Auditors shall not purchase, acquire,
      sell, loan, lease, mortgage, encumber, or otherwise deal with any real or
      personal property entrusted to their care, unless all of the following
      conditions are strictly complied with:
    </WLText>
    <WLText>
      (a) A written resolution is adopted by the Jurisdiction containing
      detailed instructions as to the property, the contents of which shall have
      been first communicated to every Jurisdictional Convention Representative
      thereof together with a written notice mailed/transmitted at least ten
      days in advance informing the Jurisdictional Convention Representatives as
      to the place and time the resolution will be offered for consideration.
      The resolution shall pass only if it receives the affirmative vote of
      two-thirds of the Jurisdictional Convention Representatives present at the
      meeting referred to in the notice.
    </WLText>
    <WLText>
      (b) The resolution and a copy of the written notice must be submitted to
      the President &amp; CEO and Secretary of WoodmenLife for approval of the
      action described in the resolution. Any and all funds which shall come
      into the possession of the Auditors as a result of any transactions
      involving Jurisdiction assets shall be paid over immediately by the
      Auditors to the Treasurer or credited to the proper account.
    </WLText>
    <WLText>
      (c) Funds of the Jurisdiction not needed to meet current expenses shall be
      invested by the Treasurer. In dealing with the funds and property of the
      Jurisdiction, the Jurisdictional Convention Officers and Treasurer shall
      observe the standards in dealing with the assets that would be observed by
      a &ldquo;prudent person&rdquo; dealing with the property of another, with
      a focus upon preservation of capital rather than income or gains, and if
      the Jurisdictional Convention Officer or Treasurer has special skills or
      is named Jurisdictional Convention Officer or Treasurer on the basis or
      representations of special skills or expertise, he or she is under a duty
      to use those skills. WoodmenLife&rsquo;s National Board of Directors can,
      by resolution, give further guidance as to investments that would be
      appropriate for the funds of a Jurisdiction.
    </WLText>
    <WLText>
      (d) In no event shall any Jurisdiction divide or distribute funds, or any
      part thereof, among its Jurisdictional Convention Representatives.
    </WLText>
    <WLText>
      <b>Sec. 4. Auditing Committee.</b>
      <br />
      Each Secretary and Treasurer shall have his/her books current and his/her
      report made noting the balance of each fund on the last day of December
      each year, and the Auditors, acting as the Auditing Committee, shall
      examine the books, vouchers and reports and give a written report to the
      President by March 1 of the following year. Such report shall be made
      available for examination by any Jurisdictional Convention Representative
      who so requests.
    </WLText>
  </>
);

export default Article5;
