import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article4: React.FC = () => (
  <>
    <WLH4>Jurisdictions</WLH4>
    <WLText>
      <b>Sec. 1. Their Establishment and Territorial Limits.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Established Jurisdictions.</b> The territory in which WoodmenLife
          is authorized to do business shall be divided into units known as
          Jurisdictions, based on the guidelines established in this Article. A
          list of Jurisdictions is included with the Jurisdictional Bylaws,
          which are attached to this Constitution and Laws.
          <br />
          Except as otherwise provided, the National Board of Directors shall
          have authority to assign members of WoodmenLife not residing in any
          Jurisdiction to a Jurisdiction.
        </WLListItem>
        <WLListItem>
          <b>Establishment of Jurisdictions.</b> Whenever there shall be 4,000
          beneficiary members in any state or combination of states, upon
          notification thereof by the President &amp; CEO and Secretary of
          WoodmenLife, the National Convention or National Board of Directors
          may establish a Jurisdiction. Additional Jurisdictions may be
          established or subdivided, provided that any state, portion of a state
          or states, formerly a part of a Jurisdiction, shall be merged into one
          or more Jurisdictions adjacent thereto. The District of Columbia shall
          be regarded as a state.
        </WLListItem>
        <WLListItem>
          <b>Abolition of Jurisdictions.</b> The National Board of Directors
          shall vacate any Jurisdiction which has less than 4,000 beneficiary
          members on November 30 for two consecutive years. The state, portion
          of a state or states comprising a Jurisdiction thus abolished shall be
          merged into or attached to one or more Jurisdictions adjacent thereto.
          When so abolished and merged, all Jurisdictional offices shall stand
          vacated except Past Presidents.
        </WLListItem>
        <WLListItem>
          <b>Jurisdictional Funds.</b> Whenever any Jurisdiction is created
          and/or abolished, as provided in this Section, the funds and all other
          property of the Jurisdiction affected thereby shall be distributed
          and/or divided as follows:
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              <b>Newly Created Jurisdictions.</b> The funds of any Jurisdiction
              from which a new Jurisdiction is created shall be divided in
              proportion to the number of benefit members residing within the
              territory of the new Jurisdictions and the number residing in the
              Jurisdiction affected by any such subdivision thereof.
            </WLListItem>
            <WLListItem>
              <b>Abolished Jurisdictions.</b> The funds of any Jurisdiction
              which has been abolished shall be divided between the
              Jurisdictions into which its former territory is merged in
              proportion to the number of benefit members residing within that
              part thereof so merged.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Past Presidents. </b>No member shall be a Past President of more
          than one Jurisdiction at any one time. Past Presidents shall be such
          only with respect to the Jurisdiction in which they were elected,
          provided they hold membership in a chapter located therein. All Past
          Presidents moving from the Jurisdiction, but retaining their
          membership in a local chapter of the Jurisdiction, shall be entitled
          to attend all Jurisdictional Conventions and have all rights and
          privileges in the Jurisdictional Conventions as any Past President
          residing within the Jurisdiction.
        </WLListItem>
        <WLListItem>
          <b>Notice to All Chapters Affected.</b> The Secretary of WoodmenLife,
          or designee thereof, shall notify the Secretary of every chapter
          affected by the establishment and/or abolition of any Jurisdiction at
          least sixty days prior to the time fixed for the convening of any such
          Jurisdictional Convention, giving the time, date and place thereof,
          which shall be fixed by the President &amp; CEO of WoodmenLife.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>
        Sec. 2. Composition of Jurisdictional Conventions; Members to Make
        Reports.
      </b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Composition.</b> Each Jurisdictional Convention shall be composed
          of the Jurisdictional Convention Representatives. Jurisdictional
          Convention Representatives include the Jurisdictional Convention
          Officers, the National Convention Delegates to the last regular
          session of the National Convention and the following, each of whom
          shall be a Good-Standing Benefit Member of a chapter located in the
          Jurisdiction: Past Jurisdictional Presidents, Jurisdictional
          Convention Delegates from chapters located therein, and the Regional
          Director from each state or division thereof. Each Jurisdictional
          Convention Representative shall be entitled to one vote on matters
          considered by the Jurisdictional Convention. No person may be a
          Jurisdictional Convention Delegate from more than one chapter or a
          Jurisdictional Convention Delegate to more than one Jurisdictional
          Convention each quadrennium.
        </WLListItem>
        <WLListItem>
          <b>Jurisdictional Convention Representatives to Make Reports.</b> All
          Jurisdictional Convention Representatives shall report to their
          respective Chapters at the next regular meeting thereafter concerning
          the actions of the Jurisdictional Convention attended by them.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 3. Jurisdictional Convention Officers and Their Duties.</b>
      <br />
      Jurisdictional Convention Officers and their duties are described in the
      Jurisdictional Bylaws, which are attached to this Constitution and Laws
      and which may be modified by the National Convention or the National Board
      of Directors.
    </WLText>
    <WLText>
      <b>
        Sec. 4. National Convention Representative from the Jurisdictional
        Convention and Alternates Thereto.
      </b>
      The President and the Immediate Past President of Jurisdictions shall, by
      virtue of their offices, be National Convention Representatives and shall
      be the sole National Convention Representatives of Jurisdictions
      comprising a single state with more than 4,000 but fewer than 7,000
      beneficiary members. Each such Jurisdiction shall also be entitled to a
      National Convention Delegate for each succeeding 4,000 such members or
      three-fourths fraction thereof within said Jurisdiction in excess of the
      first 4,000 such members.
      <br />
      Jurisdictions comprised of more than one state shall be entitled to
      National Convention Representatives in addition to the President and
      Immediate Past President as follows: one National Convention Delegate from
      each state within its territorial limits having at least 1,000 beneficiary
      members therein, except the state wherein the President resides, plus one
      National Convention Delegate-at-large for each succeeding 4,000 such
      members or three-fourths fraction thereof within said Jurisdiction in
      excess of the first 4,000 such members. Benefit membership shall be
      determined by the records of the Secretary of WoodmenLife, or designee
      thereof, as of November 30 immediately preceding any regular session of
      the Jurisdictional Convention.
      <br />
      Each Jurisdictional Convention in regular session shall elect its National
      Convention Delegates and a like number of alternates. The Secretary of
      WoodmenLife, or designee thereof, shall mail to the Jurisdictional
      Presidents and Secretaries, in ample time, a statement showing the number
      of benefit members within their Jurisdiction as of the November 30
      preceding and the number of National Convention Delegates, if any, to
      which their Jurisdiction is entitled. Benefit members of WoodmenLife who
      are benefit members of subordinate bodies located within any Jurisdiction
      shall be regarded as living within the same for all purposes hereof.
    </WLText>
    <WLText>
      <b>Sec. 5. Jurisdictional Sessions and Quorums. </b>
      Provisions describing Jurisdictional Sessions and quorum requirements are
      set forth in the Jurisdictional Bylaws, which are attached to this
      Constitution and Laws and which may be modified by the National Convention
      or the National Board of Directors.
    </WLText>
  </>
);

export default Article4;
