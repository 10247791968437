import React from 'react';

import {
  WLH4,
  WLListItem,
  WLText,
  WLUnorderedList,
} from '../../../../design-library';

const Article1: React.FC = () => (
  <>
    <WLH4>Established Jurisdictions and Purposes</WLH4>

    <WLText>
      <b>Sec. 1. Established Jurisdictions.</b>
      <br /> The territory in which the Woodmen of the World Life Insurance
      Society (&ldquo;WoodmenLife&rdquo;) is authorized to do business shall be
      divided into units known as Jurisdictions, and the same shall be named to
      reflect the geographic location in which they are organized. The specific
      Jurisdictions shall be as described in these Bylaws, in the attached List
      of Jurisdictions, and the same may be amended at any time or from time to
      time as deemed by the National Board of Directors of WoodmenLife to be in
      the best interests of WoodmenLife and/or each Jurisdiction.
    </WLText>

    <WLText>
      <b>
        Sec. 2. Purposes. Jurisdictions are subordinate bodies of WoodmenLife.
      </b>
      <br /> WoodmenLife is a fraternal benefit society that exists for the
      exclusive benefit of its members and operates under a lodge system based
      on chapters. This Jurisdiction serves the following essential purposes:
    </WLText>

    <WLText>
      (1) The Jurisdiction and its members form a part of the system that is the
      foundation through which WoodmenLife&apos;s representative form of
      government is structured and operates;
    </WLText>

    <WLText>
      (2) The Jurisdiction represents WoodmenLife in its respective geographic
      area by fostering and communicating WoodmenLife&apos;s:
      <br />
      <WLText>
        <b>Common Bond</b> – As members of WoodmenLife, we share a commitment to
        family, community and country. Through a commitment to family, we become
        more dedicated family members. Through volunteerism, we strive to be
        better friends and neighbors. Through our patriotic allegiance, we
        pledge to be better and more dedicated citizens.
      </WLText>
      <WLText>
        <b>Mission</b> – Uniting hardworking Americans to secure their financial
        future while strengthening our communities and country.
      </WLText>
      <WLText>
        <b>Values</b> – At WoodmenLife, we value:
        <br />
        <WLUnorderedList>
          <WLListItem>
            <b>PASSION FOR MEMBERS</b> – We act in the best interest of our
            members at all times, providing them with support and superior
            service. We own our actions and strive to make a difference for our
            members and their communities. We operate with speed, integrity and
            commitment.
          </WLListItem>
          <WLListItem>
            <b>SERVICE TO OTHERS</b> – We care deeply about our communities and
            our country. We give back to our members and the places they call
            home. We are there for people when they need us most.
          </WLListItem>
          <WLListItem>
            <b>INCLUSION</b> – We value our differences and respect what each of
            us brings to the table. We are committed to diversity and to
            providing opportunities for growth, leadership and service to all.
          </WLListItem>
          <WLListItem>
            <b>COLLABORATIVE SPIRIT</b> – We drive results through collaboration
            and teamwork. We share a commitment to our goals and to each other
            throughout every level of the organization. We speak openly and have
            the courage to address issues to arrive at the best results for our
            members and our associates.
          </WLListItem>
          <WLListItem>
            <b>OPEN-MINDEDNESS</b> – We are open to new ideas and improved ways
            of doing things. We are solution-oriented and future-focused. We
            embrace innovation in our pursuit of growth.
          </WLListItem>
        </WLUnorderedList>
      </WLText>
    </WLText>
  </>
);

export default Article1;
