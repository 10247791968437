import React from 'react';

import { WLText } from '../../../../design-library';

const Article5: React.FC = () => (
  <>
    <WLText>
      <b>Disputes and Grievances.</b>
      <br /> Any disputes or grievances involving a member or a chapter shall be
      resolved pursuant to the procedures set forth in Article 7, Section 1 of
      WoodmenLife&rsquo;s Constitution and Laws and such other procedures as may
      be established by WoodmenLife&rsquo;s National Board of Directors for
      handling such disputes or grievances.
    </WLText>
  </>
);

export default Article5;
