import React from 'react';

import { WLText } from '../../../../design-library';

const Article5: React.FC = () => (
  <>
    <WLText>
      <b>Supreme Governing Body. </b>WoodmenLife‘s supreme governing body shall
      be known as the ”National Convention,“ which shall be composed of its
      National Convention Representatives as provided for and defined in the
      Constitution and Laws.
    </WLText>
    <br />
    <WLText>
      Regular sessions of the National Convention shall be held as often as once
      in each four calendar years at such times and places as may be determined
      by the President & CEO of WoodmenLife. Special sessions or referenda may
      occur as provided by the Constitution and Laws of WoodmenLife.
    </WLText>
  </>
);

export default Article5;
