import React from 'react';

import { WLText } from '../../../../design-library';
import { ConstitutionSection } from '../../index';
import Article1 from './Article1';
import Article2 from './Article2';
import Article3 from './Article3';
import Article4 from './Article4';
import Article5 from './Article5';
import Article6 from './Article6';
import Article7 from './Article7';

const JurisdictionalBylaws: ConstitutionSection = {
  title: 'Jurisdictional Bylaws',
  revised: 'January 1, 2023',
  commonIntro: (
    <WLText as="b">
      (The following are rules which prescribe the governance of Jurisdictions
      of WoodmenLife and should be adopted as provisions of the Bylaws of the
      Jurisdiction.)
    </WLText>
  ),
  articles: [
    {
      name: 'Article 1',
      content: <Article1 />,
    },
    {
      name: 'Article 2',
      content: <Article2 />,
    },
    {
      name: 'Article 3',
      content: <Article3 />,
    },
    {
      name: 'Article 4',
      content: <Article4 />,
    },
    {
      name: 'Article 5',
      content: <Article5 />,
    },
    {
      name: 'Article 6',
      content: <Article6 />,
    },
    {
      name: 'Article 7',
      content: <Article7 />,
    },
  ],
};

export default JurisdictionalBylaws;
