import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article1: React.FC = () => (
  <>
    <WLH4>Judiciary Committee and Appeals Thereto</WLH4>
    <WLText>
      <b>Jurisdiction and Powers.</b>
      <br />
      The Judiciary Committee shall have appellate de novo jurisdiction, based
      on the written record only, in all cases where the alleged offender is
      found guilty of violating any provision of this Constitution and Laws,
      provided an appeal is taken as provided in Article 7, Section 1 of this
      Constitution and Laws, within thirty days after written notice is received
      by the person found guilty, setting forth the grounds, together with the
      record, or that part thereof, upon which the appeal is based and the same
      is filed with the Secretary of WoodmenLife. The Secretary of WoodmenLife,
      or designee thereof, shall forward the appeal, together with the record of
      said proceedings, to the Secretary of the Judiciary Committee. The
      committee shall meet prior to each regular session of the National
      Convention when any such appeal is pending, at which time it shall
      consider all such appeals and render a written decision thereon, which
      shall be reported by the Chairman to the National Convention, which may
      affirm or reverse the decision in whole or in part. The Judiciary
      Committee may allow arguments by the appellant and respondent, or any
      attorney on their behalf, during the consideration of any appeal.
    </WLText>
  </>
);

export default Article1;
