import React from 'react';

import { WLText } from '../../../../design-library';

const Article7: React.FC = () => (
  <>
    <WLText>
      <b>Amendments.</b>
      <br />
      These Bylaws may be amended in the following manner only: (a) by the
      method set forth in Article 2, Section 2 (c), of WoodmenLife&rsquo;s
      Constitution and Laws; or (b) by adopting proposals for such amendments in
      writing, originating from any of the regular meetings of a Jurisdiction or
      the National Convention and enacted in accordance with Article 9, Section
      1 of WoodmenLife&rsquo;s Constitution and Laws.
    </WLText>
  </>
);

export default Article7;
