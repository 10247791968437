import React from 'react';

import { WLText } from '../../../../design-library';

const Article6: React.FC = () => (
  <>
    <WLText>
      <b>Disputes and Grievances.</b>
      <br />
      Any disputes or grievances involving a Jurisdictional Convention
      Representative may be submitted by a Jurisdictional Convention
      Representative having knowledge of the issue with the President of the
      Jurisdiction. The President of the Jurisdiction shall review the dispute
      or grievance and render a determination within thirty days of receipt of
      the dispute or grievance. The President of the Jurisdiction&rsquo;s
      determination of the dispute or grievance may be appealed in writing to
      the President &amp; CEO of WoodmenLife within thirty days of the President
      of the Jurisdiction&rsquo;s determination. The President &amp; CEO
      decision shall be final unless the member or Jurisdiction files a written
      appeal of the decision within thirty days to the Judiciary Committee as
      provided for in Article 8 of the Constitution and Laws. The decision of
      the President &amp; CEO will be effective unless and until the decision is
      modified by the Judiciary Committee.
    </WLText>
  </>
);

export default Article6;
