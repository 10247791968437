import React from 'react';

import { WLText } from '../../../../design-library';

const Article6: React.FC = () => (
  <WLText>
    <b>Constitution and Laws. </b>WoodmenLife shall have the power, through its
    National Convention or National Board of Directors, to provide a
    Constitution and Laws; to provide rules and regulations for its own
    government and that of its chapters and members and certificate holders; to
    alter and amend the same at any session of the National Convention or
    meeting of its National Board of Directors; to provide for the organization
    of subordinate, subsidiary or affiliated entities such as local chapters and
    Jurisdictional Conventions and prescribe the laws, rules and regulations for
    their government; and to do all other acts and things necessary to carry out
    the purposes and objectives for which it is organized.
  </WLText>
);

export default Article6;
