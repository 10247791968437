import React from 'react';

import { ConstitutionSection } from '../../index';
import Content from './Content';

const EstablishedJurisdictions: ConstitutionSection = {
  title: 'Established Jurisdictions WoodmenLife',
  revised: 'January 1, 2023',
  content: <Content />,
};

export default EstablishedJurisdictions;
