import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article9: React.FC = () => (
  <>
    <WLH4>Amendments and Other Matters</WLH4>
    <WLText>
      <b>Sec. 1. Modes of Amending.</b>
      <br />
      This Constitution and Laws may be altered or amended at any session of the
      National Convention by two-thirds vote of the National Convention
      Representatives constituting the same or any meeting of the National Board
      of Directors by a two thirds vote of the members thereof or otherwise the
      same shall be rejected.
    </WLText>
    <WLText>
      <b>Sec. 2. Amendment Proposals.</b>
      <br />
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>
            Duties of the Governance Committee of the National Board of
            Directors.
          </b>
          The Governance Committee of the National Board of Directors may
          recommend and/or review proposals for amending WoodmenLife&rsquo;s
          Articles, Constitution and Laws and such recommendations, if any,
          shall be submitted to the National Board of Directors for
          consideration.
        </WLListItem>
        <WLListItem>
          <b>Duties of General Counsel. </b>WoodmenLife&rsquo;s General Counsel
          or his designee(s) shall consider all proposals for amending the
          Constitution and Laws as may be submitted to him from any National
          Director and the General Counsel shall submit such proposal along with
          his analysis, if any, to the National Board of Directors.
          WoodmenLife&rsquo;s General Counsel or his designee shall further
          consider all proposals for amending the Constitution and Laws from any
          of the Jurisdictional Conventions and/or the National Convention
          Officers that were submitted to the General Counsel at least sixty
          days prior to the National Convention, and he shall present his
          recommendations, if any, to the, Legislation Committee in writing.
        </WLListItem>
        <WLListItem>
          <b>Duties of Legislation Committee.</b> The President &amp; CEO of
          WoodmenLife shall establish a time and place for the Legislation
          Committee to meet prior to each regular session of the National
          Convention. The Committee shall consider all proposals for amending
          this Constitution and Laws as may be submitted to it from the General
          Counsel pursuant to Article 9, Section 2(b) of this Constitution and
          Laws. The Committee shall submit in writing its recommendations, if
          any, to the next regular session of the National Convention for
          consideration by the National Convention Representatives.
        </WLListItem>
        <WLListItem>
          <b>Two-Thirds Vote Required to Adopt.</b> The recommendations of the
          Legislation Committee, if any, shall be submitted to the next regular
          session of the National Convention which may adopt same only by a
          two-thirds vote of all National Convention Representatives, or
          otherwise the same shall be rejected.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 3. Power to Correct Typographical Errors.</b>
      <br />
      The National Convention directs the Executive Officers of WoodmenLife to
      correct typographical errors that might appear in the foregoing
      Constitution and Laws and to renumber the Articles, Sections, subsections,
      and/or subparagraphs in codifying the same so as to place them in proper
      order but without changing the context, intent and purpose thereof.
    </WLText>
    <WLText>
      Should any Article, Section, or part of this Constitution and Laws be held
      invalid for any reason whatsoever by any court of competent jurisdiction,
      such holding shall not affect the remainder or any part thereof.
    </WLText>
    <WLText>
      <b>Sec. 4. Masculine Includes Feminine and Plural the Singular.</b>
      <br />
      Whenever in this Constitution and Laws and attachments thereto the
      masculine pronoun is used, it shall be deemed to include the feminine.
      Whenever any word or words denoting the singular number is used, it shall
      be deemed to include the plural, and vice versa; provided, however, the
      context thereof does not clearly prohibit such interpretation.
    </WLText>
    <WLText>
      <b>Sec. 5. Effective Date of This Constitution and Laws. </b>
      <br />
      This Constitution and Laws and attachments thereto, as amended and adopted
      by a regularly scheduled meeting of WoodmenLife&rsquo;s National Board of
      Directors and effective January 1, 2023, shall be in full force and effect
      on and after January 1, 2023, and shall apply to all benefit certificates
      of membership issued or assumed by WoodmenLife.
    </WLText>
    <WLText>
      <b>Sec. 6. Parliamentary Authority.</b>
      <br />
      For all matters of procedure before the National Convention, the National
      Board of Directors and subordinate bodies, the most currently available
      edition of <i>Robert&rsquo;s Rules of Order</i> shall be the parliamentary
      authority unless specifically covered in this Constitution and Laws or by
      specific rules of procedure adopted by the National Board of Directors.
    </WLText>
  </>
);

export default Article9;
