import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import {
  WLAccordion,
  WLAccordionHeader,
  WLAccordionItem,
  WLAccordionPanel,
  WLH2,
  WLH3,
  WLText,
} from '../../design-library';
import { ConstitutionArticle, ConstitutionSection } from './index';
import Sections from './Sections';

interface ConstitutionProps {}

interface ItemDisplayProps {
  section: ConstitutionSection | null;
  article: ConstitutionArticle | null;
}

const ItemDisplay: React.FC<ItemDisplayProps> = ({ section, article }) => {
  if (!section) {
    return null;
  }

  let content;
  if (article) {
    content = (
      <>
        <WLH3>{article.name}</WLH3>
        {article.content}
      </>
    );
  } else {
    content = section.content;
  }

  return (
    <>
      <WLH2>{section.title}</WLH2>
      <WLText as="small">Revised {section.revised}</WLText>
      <Box paddingY={4}>{section.commonIntro}</Box>
      {content}
    </>
  );
};

const Constitution: React.FC<ConstitutionProps> = () => {
  const [selectedSection, setSelectedSection] =
    React.useState<null | ConstitutionSection>(null);
  const [selectedArticle, setSelectedArticle] =
    React.useState<null | ConstitutionArticle>(null);
  const selectSection = React.useCallback((section: ConstitutionSection) => {
    setSelectedArticle(null);
    setSelectedSection(section);
  }, []);
  const selectArticle = React.useCallback((article: ConstitutionArticle) => {
    setSelectedArticle(article);
  }, []);

  return (
    <SimpleGrid
      templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 2fr' }}
      columnGap={4}
      width="full"
    >
      <Box width="full">
        <WLAccordion width="full" allowToggle>
          {Sections.map((section, sectionIndex) => (
            <WLAccordionItem key={sectionIndex} width="full">
              <WLAccordionHeader
                onClick={() => selectSection(section)}
                width="full"
              >
                {section.title}
              </WLAccordionHeader>
              {section.articles && (
                <WLAccordionPanel>
                  {section.articles.map((article, articleIndex) => (
                    <Button
                      key={articleIndex}
                      variant="ghost"
                      width="full"
                      rightIcon={<ChevronRightIcon />}
                      onClick={() => selectArticle(article)}
                    >
                      {article.name}
                    </Button>
                  ))}
                </WLAccordionPanel>
              )}
            </WLAccordionItem>
          ))}
        </WLAccordion>
      </Box>
      <Box width="full">
        <ItemDisplay section={selectedSection} article={selectedArticle} />
      </Box>
    </SimpleGrid>
  );
};

export default Constitution;
