import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article2: React.FC = () => (
  <>
    <WLH4>Membership</WLH4>
    <WLText>
      <b>Sec. 1. Composition.</b>
      <br />
      Each Jurisdictional Convention shall be comprised of its Jurisdictional
      Convention Representatives, which consists of its Jurisdictional
      Convention Officers, its National Convention Delegates to the last regular
      session of the National Convention, members of National Committees
      residing in the Jurisdiction, and the following, each of whom shall be a
      Good-Standing Benefit Member of a chapter located in the Jurisdiction:
      Past Jurisdictional Convention Presidents, Jurisdictional Convention
      Delegates from chapters located therein, Fraternal Service Committee
      members and the Regional Director from each state or division thereof
      within the Jurisdiction.
    </WLText>
    <WLText>
      <b>
        Sec. 2. Jurisdictional Convention Representatives Leaving the
        Jurisdiction.
      </b>
      <br />
      Jurisdictional Convention Representatives relocating outside the
      Jurisdiction shall be considered to have voluntarily resigned their
      representation in the Jurisdiction. In the event a Jurisdictional
      Convention Delegate relocates or is not a Good Standing Benefit Member of
      a chapter located in the Jurisdiction, the Alternate Jurisdictional
      Convention Delegate elected by the chapter from which the resigning member
      was elected shall take over his/her representation. In the case of a
      Jurisdictional Convention Officer, the President of the Jurisdiction shall
      appoint a replacement until the vacancy is filled under the election
      procedure described in Article 3, Section 11(a) of these Bylaws.
    </WLText>
    <WLText>
      <b>Sec. 3. Duty to Assist All Sales Associates.</b>
      <br />
      All Jurisdictional Convention Representatives shall cooperate and aid
      authorized WoodmenLife Sales associates and Regional Directors in securing
      increased membership and retaining members. Jurisdictional Convention
      Representatives working counter to this purpose and/or counter to the
      mission and vision of WoodmenLife shall stand suspended from the
      Jurisdiction and may not participate in any of the business or social
      affairs of the Jurisdictional Convention or National Convention. "Working
      counter to this purpose" includes, but is not limited to, becoming an
      agent of, employed by or independently contracted with, or in any other
      manner, including selling or promoting products of or becoming a holder of
      a position of trust with, another fraternal benefit society or life
      insuring institution, unless such sales activity and products are
      permitted by WoodmenLife.
    </WLText>
  </>
);

export default Article2;
