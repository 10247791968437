import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article3: React.FC = () => (
  <>
    <WLH4>Membership, Equal Access, Meetings and Quorum Requirements</WLH4>
    <WLText>
      <b>Sec. 1. Membership. </b>
      <br />
      The chapter shall accept members in accordance with WoodmenLife&rsquo;s
      Constitution and Laws.
    </WLText>
    <WLText>
      <b>Sec. 2. Transfer of Membership.</b>
      <br />A member may transfer membership to another chapter by submitting a
      transfer card to the Home Office. Transfer cards shall be issued in
      accordance with WoodmenLife&rsquo;s Constitution and Laws.
    </WLText>
    <WLText>
      <b>Sec. 3. Equal Access.</b>
      <br />
      The chapter shall implement and enforce WoodmenLife&rsquo;s Equal Access
      Policy, which states: WoodmenLife is an equal access fraternal benefit
      society. It is the policy of WoodmenLife to seek qualified members on a
      nondiscriminatory basis and to provide all members with equal access to
      and allow their participation in WoodmenLife&rsquo;s lodge system based on
      chapters, chapter events, customer benefits and all other fraternal
      activities on a nondiscriminatory basis.
    </WLText>
    <WLText>
      <b>Sec. 4. Regular Meetings.</b>
      <br />
      Chapters shall schedule regular meetings on such dates, times and places
      as decided by them, as required by law, and in conformity with
      WoodmenLife&rsquo;s chapter calendar guidelines. Failure to hold meetings
      as required under this provision for a period of one year may result in
      the following: suspension of the chapter charter, consolidation with
      another chapter, forfeiture of funds remittance, or transfer of members to
      another chapter(s) by the President &amp; CEO of WoodmenLife.
    </WLText>
    <WLText>
      <b>Sec. 5. Special Meetings.</b>
      <br />
      Special meetings of chapters may be called by the Chapter President or, in
      his/her absence, by the Vice President, at any time, and shall be called
      when requested in writing to do so by at least three members. The call of
      any such special meeting must state the object of the meeting and be
      mailed by the Secretary to each member&rsquo;s last-known address at least
      ten days prior to the date of such meeting. No other business than that
      stated in the notice shall be considered at the special meeting.
    </WLText>
    <WLText>
      <b>Sec. 6. Quorum. </b>
      <br />
      Five members at a regular or special chapter meeting shall constitute a
      quorum. A fewer number may adjourn to another date.
    </WLText>
    <WLText>
      <b>Sec. 7. Electronic Meetings. </b>
      <br />
      Regular and special chapter meetings may be conducted electronically in
      the event of war, national emergency, or other circumstances as may
      justify such actions as determined by the President &amp; CEO of
      WoodmenLife, in his or her sole discretion, and provided that electronic
      sessions shall utilize a means of communication by which the participants
      may simultaneously hear each other during the meeting.
    </WLText>
  </>
);

export default Article3;
