import React from 'react';

import { WLText } from '../../../../design-library';

const Article9: React.FC = () => (
  <WLText>
    <b>Amendments. </b>These Articles of Incorporation may be altered or amended
    at any session of the National Convention by a two-thirds vote of the
    National Convention Representatives or at any meeting of the National Board
    of Directors by a two-thirds vote of the members thereof.
  </WLText>
);

export default Article9;
