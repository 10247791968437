import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article4: React.FC = () => (
  <>
    <WLH4>Chapter Funds, Property, Audits, and Bonds</WLH4>
    <WLText>
      <b>Sec. 1. Chapter Funds and Property.</b>
      <br />
      Legal title to all property, real, personal and mixed, as well as
      securities, shall be held in the name of and owned by the chapter. The
      Treasurer shall not pay out, or contract to pay out, any sum of money,
      except for current expenses, and the chapter officers shall not purchase,
      acquire, sell, loan, lease, mortgage, encumber, or otherwise deal with any
      real or personal property entrusted to their care, unless all of the
      following conditions are strictly complied with:
    </WLText>
    <WLText>
      (a) A written resolution is adopted by the chapter containing detailed
      instructions as to the property, the contents of which shall have been
      first communicated to every member thereof, together with a written notice
      mailed/transmitted at least ten days in advance informing the member as to
      the place and time the resolution will be offered for consideration. The
      resolution shall pass only if it receives the affirmative vote of
      two-thirds of the members present at the meeting referred to in the
      notice.
    </WLText>
    <WLText>
      (b) The resolution and a copy of the written notice must be submitted to
      the President &amp; CEO and Secretary of WoodmenLife for approval of the
      action described in the resolution. If approved, the chapter shall appoint
      and authorize two chapter officers to execute any and all documents
      necessary to effectuate the transaction involving chapter assets,
      including real property. Any and all funds which shall come into the hands
      of the chapter officers as a result of any transactions involving chapter
      assets shall be paid over immediately by the chapter officers to the
      Treasurer of the chapter or credited to the proper account.
    </WLText>
    <WLText>
      (c) Funds of the chapter not needed to meet current expenses shall be
      invested by the Treasurer. In dealing with the funds and property of the
      chapter, the chapter officers and Treasurer shall observe the standards in
      dealing with the assets that would be observed by a &ldquo;prudent
      person&rdquo; dealing with the property of another, with a focus upon
      preservation of capital rather than upon income or gain, and if the
      chapter officer has special skills, or is named chapter officer on the
      basis or representations of special skills or expertise, he/she is under a
      duty to use those skills. WoodmenLife&rsquo;s National Board of Directors
      can, by resolution, give further guidance as to investments that would be
      appropriate for the funds of a chapter.
    </WLText>
    <WLText>
      (d) In no event shall any Chapter divide or distribute funds, or any part
      thereof, among its members. However, nothing in these Bylaws shall
      preclude monetary donations to members facing extreme hardship or illness.
      Such distributions shall be brought to the membership during a regular or
      special meeting and be voted on by a majority of members present.
    </WLText>
    <WLText>
      <b>Sec. 2. General Fund.</b>
      <br />
      The general fund shall be used for the payment of the necessary expenses
      of conducting the business of the chapter and for such other purposes as
      may be provided by the Bylaws of the chapter. The general fund of the
      chapter shall be used only for the purpose of promoting the welfare of the
      chapter and for the perpetuation of the chapter as an organization, and
      its property shall never be divided among its members.
    </WLText>
    <WLText>
      <b>Sec. 3. Auditing Committee.</b>
      <br />
      Each Secretary and Treasurer shall have his/her books current and his/her
      report made out noting the balance of each fund on the last day of
      December, and the Auditors shall examine the books, vouchers and reports,
      and give a report at the first regular meeting in the following February.
    </WLText>
    <WLText>
      <b>Sec. 4. Bonds of Officers. </b>
      <br />
      WoodmenLife&rsquo;s National Board of Directors and/or members of a
      chapter may require such officers of chapters to furnish a bond to insure
      the faithful performance of their duties, the same to be in such forms and
      sums as they shall determine. Bonds required by the National Board of
      Directors shall be paid for by WoodmenLife and those required by the
      chapter shall be paid for by the chapter. A blanket bond, instead of
      individual bonds, may be authorized.
    </WLText>
  </>
);

export default Article4;
