import React from 'react';

import { WLListItem, WLOrderedList, WLText } from '../../../../design-library';

const Article7: React.FC = () => (
  <WLText>
    <b>Membership.</b>

    <WLOrderedList listStyleType="lower-alpha">
      <WLListItem>
        <b>Benefit Members. </b>A benefit member is a person who meets the
        qualifications set forth in Article 4 above or is a member of a group
        and who has been accepted for membership. Each member shall also be a
        member of a subordinate body of WoodmenLife (Chapter). Benefit members
        shall be divided into the following classes:
        <WLOrderedList listStyleType="decimal">
          <WLListItem>
            <b>Adult Benefit Members. </b>A person who is accepted for
            membership and who is either sixteen years of age or older, or not
            considered a minor in his/her state of residence shall be an adult
            benefit member of WoodmenLife.
          </WLListItem>
          <WLListItem>
            <b>Youth Benefit Members. </b>A person who is accepted for
            membership and who is under sixteen years of age shall be a youth
            benefit member of WoodmenLife. Such members shall have those rights
            of membership as may be provided in the benefit certificate or by
            the National Board of Directors.
          </WLListItem>
          <WLListItem>
            <b>Associate Benefit Members. </b>A person who is accepted for
            membership as a member of a family or group subscribing to a benefit
            certificate shall be an associate member of WoodmenLife. Such member
            shall have those rights of membership as may be provided in the
            benefit certificate or by the National Board of Directors.
          </WLListItem>
        </WLOrderedList>
      </WLListItem>
      <WLListItem>
        <b>Good-Standing Benefit Members. </b>A Good-Standing Benefit Member is
        one who has made all payments according to the terms of the benefit
        certificate or under this Constitution and Laws. Benefit members of
        WoodmenLife who are not in good standing shall not hold any office in
        the National Convention or any of the subordinate bodies of WoodmenLife
        or attend or participate in any business, social or other meeting
        thereof which is limited to Good-Standing Benefit Members.
      </WLListItem>
      <WLListItem>
        <b>Social Members. </b>A person who has been accepted for social
        membership and who meets the qualifications set forth in Article 4 above
        and who does not hold a good-standing benefit certificate is a social
        member of WoodmenLife. Social members shall not be eligible to hold any
        office in or be a National Convention or Jurisdictional Convention
        Representative, or have any voice in the election of National Convention
        and Jurisdictional Convention Delegates.
      </WLListItem>
    </WLOrderedList>
  </WLText>
);

export default Article7;
