import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article2: React.FC = () => (
  <>
    <WLH4>The National Board of Directors</WLH4>
    <WLText>
      <b>Sec. 1. Composition and Independent National Directors.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Composition.</b> The National Board of Directors shall be composed
          of the President &amp; CEO (who shall serve as the Chairman of the
          National Board of Directors unless determined otherwise in accordance
          with Article 2, Section 9(a) of this Constitution and Laws); three
          Executive Vice Presidents recommended by the President and CEO as part
          of the nominating process at each National Convention; and up to ten
          (10) additional members, at least eight of whom shall be Independent
          National Directors as defined in Article 2, Section 1(b) of this
          Constitution and Laws. If the National Board Chairman so elected is an
          Independent National Director, he shall be counted as one of the eight
          Independent National Directors as described in this Article 2, Section
          1(a). Other than the President &amp; CEO and three Executive Vice
          President positions, at no time shall the National Board of Directors
          contain more than two National Directors who have been previously
          employed or contracted with WoodmenLife as an Executive Officer, Home
          Office Employee, Recruiting Sales Manager, or Regional Director within
          the previous ten years unless such requirement is waived by a
          unanimous vote of the National Board of Directors.
        </WLListItem>
        <WLListItem>
          <b>Independent National Director.</b> An Independent National Director
          is any individual who has not been actively employed with WoodmenLife
          within the previous three years as an Executive Officer, Home Office
          Employee, Recruiting Sales Manager, or Regional Director.
        </WLListItem>
      </WLOrderedList>
      <WLText>
        <b>Sec. 2. Duties and Powers.</b>
        <WLOrderedList listStyleType="lower-alpha">
          <WLListItem>
            <b>Powers.</b> All power and authority of the National Convention,
            when not in session, shall be vested in the National Board of
            Directors, except as herein elsewhere provided.
          </WLListItem>
          <WLListItem>
            <b>Examine Transactions of its Members.</b> The National Board of
            Directors may examine the transactions of its members at any time
            and shall receive such reports from its members as are required of
            them in this Constitution and Laws. It shall transact any other
            business rightfully before it.
          </WLListItem>
          <WLListItem>
            <b>Authority.</b> It shall have the authority to act on behalf of
            the National Convention between regular sessions of the National
            Convention. The National Board of Directors shall have the authority
            to approve mergers, divestitures, consolidations, and acquisitions,
            subject to and in accordance with applicable laws, and pursuant
            thereto has the authority to execute and enter into merger,
            divestiture, consolidation, or any form of acquisition agreements,
            articles of merger, divestiture or consolidation, certificates of
            assumption, representations and warranties, as well as any other
            documents necessary to effectuate a merger, divestiture,
            consolidation or any acquisition. The National Board of Directors
            shall have further authority, subject to and in accordance with
            applicable laws and any required regulatory approval, to borrow
            money and issue financial instruments in connection therewith,
            including, without limitation, promissory notes, surplus notes or
            such other instruments of indebtedness as approved by the National
            Board of Directors, and pursuant thereto to enter into, execute and
            deliver such financing agreements and instruments for such purposes
            as the National Board of Directors deems necessary and appropriate,
            including, without limitation, financing to effectuate mergers,
            divestitures, consolidations, or acquisitions.
            <br />
            Legislation and other matters may be adopted by means of a
            referendum submitted by mail to qualified National Convention
            Representatives when the same is approved as herein provided.
            Qualified National Convention Representatives, for purposes of a
            referendum, shall be those National Convention Representatives of
            the last regular session of the National Convention who are eligible
            to act in the capacity as a National Convention Representative at
            the time of the referendum.
            <br />
            The National Board of Directors shall have authority to make rules
            and regulations for the orderly operation of Chapters and
            Jurisdictions, which shall include, but not be limited to, changes
            in the Bylaws and Rules of Order of subordinate bodies, subsidiaries
            and affiliated entities as defined in WoodmenLife&rsquo;s Articles
            of Incorporation and Constitution and Laws, provided such changes do
            not conflict with this Constitution and Laws.
          </WLListItem>
          <WLListItem>
            <b>Power in Event of Taxes and Additional Payments.</b> In the event
            any state or other taxing authority shall place any levy, tax or
            other charge upon any benefit certificate of membership issued by
            WoodmenLife and/or on any payments made by members, the National
            Board of Directors and/or the National Convention is hereby
            authorized to add such amount, or any part thereof, to the payments
            otherwise prescribed by WoodmenLife for any such certificates
            subject thereto, and the National Board of Directors and/or the
            National Convention may direct that the amount of such levy, tax or
            charge shall be a lien on the proceeds of any such certificates, or
            if a refund is made, it may be deducted therefrom, in both cases
            with interest thereon at the rate of five percent per annum,
            compounded annually.
          </WLListItem>
          <WLListItem>
            <b>Funds of WoodmenLife.</b> The funds of WoodmenLife shall be as
            follows:
            <WLOrderedList listStyleType="decimal">
              <WLListItem>
                <b>Insurance Fund.</b> The Insurance Fund shall be a sum equal
                to the liability of WoodmenLife in connection with all benefit
                certificates and accrued expenses in relation to such
                certificates, together with an additional special reserve for
                fluctuation of assets, mortality, morbidity, interest earnings
                and expense. The National Board of Directors may provide for the
                establishment and operation of one or more separate accounts and
                issue contracts on a variable basis providing for the dollar
                amount of benefits or other contractual payments or values
                thereunder to vary so as to reflect the investment results of
                such separate accounts.
              </WLListItem>
              <WLListItem>
                <b>Fraternal Fund.</b> The Fraternal Fund of WoodmenLife shall
                consist of all of the assets of WoodmenLife, except those set
                forth in subsection (1) hereof. The liabilities of the Fraternal
                Fund shall be all liabilities of WoodmenLife except those in
                connection with benefit certificates and accrued expenses in
                connection with such benefit certificates.
              </WLListItem>
            </WLOrderedList>
          </WLListItem>
        </WLOrderedList>
      </WLText>
      <WLText>
        <b>Sec. 3. Meetings.</b>
        <WLOrderedList listStyleType="lower-alpha">
          <WLListItem>
            <b>Regular.</b> The National Board of Directors shall hold regular
            meetings at least semiannually on such dates and at such places as
            may be determined by it.
          </WLListItem>
          <WLListItem>
            <b>Special.</b> Special meetings of the National Board of Directors
            may be called by the Chairman of the National Board or the President
            &amp; CEO, at such times and places as either shall determine,
            whenever either of them deems it necessary for the good of
            WoodmenLife, and shall be called whenever five members of the
            National Board of Directors, in writing, request the same.
          </WLListItem>
          <WLListItem>
            <b>Quorum.</b> A majority of the members of the National Board of
            Directors shall constitute a quorum at all meetings, but the
            affirmative vote of a majority of the entire membership thereof
            qualified to vote thereon shall be required to determine all issues
            rightfully before it, except as provided to the contrary in this
            Constitution and Laws.
          </WLListItem>
          <WLListItem>
            <b>Guests.</b> Guests may be invited by the Chairman of the National
            Board or the President &amp; CEO to attend any regular or special
            meeting of the National Board of Directors. Guests may neither vote
            nor participate in any debate regarding a matter; however, guests
            may be called upon by the Chairman of the National Board or the
            President &amp; CEO to present information and answer relevant
            questions regarding a matter properly before the National Board of
            Directors.
          </WLListItem>
          <WLListItem>
            <b>Conduct</b>. Any meeting, regular or special, of the National
            Board of Directors may be conducted by telephone and/or video
            conference at the discretion of the Chairman of the National Board.
            Furthermore, in the event a National Director is unable to appear in
            person at any meeting, regular or special, of the National Board of
            Directors, said Director may appear via telephone and/or video
            conference at the discretion of the Chairman of the National Board.
          </WLListItem>
        </WLOrderedList>
      </WLText>
      <WLText>
        <b>Sec. 4. Rule Making Powers; Standing and Special Committees.</b>
        <br /> The National Board of Directors may adopt rules under which it
        may operate and discharge its duties. No roll call vote shall be
        required on any issue except in the discretion of the Chairman of the
        National Board or upon the request of two-thirds of the members of the
        National Board of Directors. Any vote may be conducted via secret ballot
        at the discretion of the Chairman of the National Board or upon the
        request of three or more members of the National Board of Directors and
        any such rules adopted under this section or previously adopted by the
        National Board prior to the adoption of this section shall remain in
        full force and effect until such time as two-thirds of the members of
        the National Board of Directors vote to amend or eliminate said rule.
        The Chairman of the National Board may appoint special committees of the
        National Board of Directors and Chairs of such committees with the
        approval of the National Board to expedite work and matters before it
        for consideration. The standing committees shall be the Audit,
        Governance, Compensation, Fraternal, Executive and Investment
        Committees. Members of the standing committees shall be appointed by the
        Chairman of the National Board on an annual basis, subject to approval
        by a majority vote of the National Board, and shall be comprised of no
        fewer than three or greater than eight Independent National Directors.
        The members of each standing committee and the Chairman thereof shall be
        appointed solely on the basis of his/her qualifications to serve on a
        standing committee. With the exception of the Executive and Investment
        Committees, no person shall serve as Chairman of more than one standing
        committee. The number of members of each standing committee shall be
        determined by the National Board of Directors consistent with the
        requirements set forth herein. Notwithstanding these requirements, the
        President &amp; CEO and one other Executive Officer recommended by the
        President &amp; CEO and approved by the National Board shall be members
        of the Governance Committee, in addition to no fewer than three
        Independent National Directors.
        <WLOrderedList listStyleType="lower-alpha">
          <WLListItem>
            <b>Audit Committee.</b> This committee shall transact all business
            and perform such duties as may be required of it by the National
            Convention, the National Board of Directors or the President &amp;
            CEO, and/or as required by law, and shall otherwise perform
            oversight and review as it shall determine to be appropriate in the
            proper exercise of its sound judgment and discretion. This committee
            shall have as its purpose to provide oversight and monitoring of
            WoodmenLife&rsquo;s internal and outside auditors, financial
            operations, accounting practices, enterprise risk management and
            other matters as dictated by the judgment of the committee under
            guidelines established by the National Board of Directors. This
            committee and/or the National Board of Directors may, in its
            discretion, order an examination or audit, in whole or in part, of
            the affairs of WoodmenLife by an independent agency as often as it
            shall see fit and shall do so if the Department of Insurance of the
            State of Nebraska shall fail to do so at such intervals as required.
          </WLListItem>
          <WLListItem>
            <b>Governance Committee.</b> The Governance Committee shall
            recommend to the National Board of Directors applicable governance
            guidelines; facilitate an annual review of the National
            Board&rsquo;s engagement and the President &amp; CEO&rsquo;s
            performance for review by the National Board; recommend and/or
            review proposals to amend WoodmenLife&rsquo;s Articles, Constitution
            and Laws; and identify individuals qualified to become National
            Directors other than Executive Vice Presidents, consistent with
            criteria approved by the National Board. While the National Board of
            Directors shall be responsible for the election of a President &amp;
            CEO, the Governance Committee may assist the National Board, if so
            requested, to seek applicants, conduct interviews and/or recommend
            individual(s) for the position of President &amp; CEO. Moreover,
            while the President &amp; CEO is solely responsible for the
            appointment of all Executive Vice President positions, the
            Governance Committee may assist the President &amp; CEO, if so
            requested, to seek applicants, conduct interviews and/or recommend
            individual(s) for a vacant Executive Vice President position.
            Notwithstanding anything to the contrary herein, any member of the
            National Board of Directors shall be empowered to make a nomination
            on his/her own initiative at any special or regular National Board
            meeting, and such nomination, if seconded, shall be considered by
            the National Board of Directors.
          </WLListItem>
          <WLListItem>
            <b>Compensation Committee.</b> This committee shall initiate and
            certify to the National Board for its approval and ratification the
            regular and supplemental compensation and benefits of all elected
            Executive Officers and members of the National Board of Directors.
            No Executive Officer or member of the National Board of Directors
            shall vote to approve a recommendation of this committee involving
            his or her regular or supplemental compensation or benefits.
          </WLListItem>
          <WLListItem>
            <b>Fraternal Committee.</b> This committee shall review and
            recommend to the National Board of Directors various fraternal
            initiatives, programs and benefits for members and the communities
            served by WoodmenLife members that are consistent with
            WoodmenLife&rsquo;s mission, values and strategy, and consistent
            with the laws and regulations set forth for fraternal benefit
            societies.
          </WLListItem>
          <WLListItem>
            <b>Executive Committee.</b>
            <WLOrderedList listStyleType="decimal">
              <WLListItem>
                <b>Supervision and Control Over Home Office Associates.</b> The
                Executive Committee shall have supervision and control over the
                employment, duties, compensation, and general welfare of all
                persons employed by the Home Office, but may delegate such
                duties as it shall deem advisable, provided that no person,
                other than a member, shall be employed full-time by WoodmenLife.
              </WLListItem>
              <WLListItem>
                <b>Operational Authority Over Group Benefits.</b> The Executive
                Committee shall have supervision and operational authority over
                the group benefits (which includes the right to amend the plans)
                of all associates. Group benefits include, but are not limited
                to, retirement, health, dental, disability, and life.
              </WLListItem>
              <WLListItem>
                <b>Operational Authority Over Other Matters.</b> The Executive
                Committee shall have supervision and operational authority over
                all matters involving WoodmenLife not delegated to the President
                &amp; CEO or to another officer or to the Investment Committee
                or to a committee of the National Convention and not
                inconsistent with the powers of the National Board of Directors.
              </WLListItem>
            </WLOrderedList>
          </WLListItem>
          <WLListItem>
            <b>Investment Committee.</b> The Investment Committee shall have
            full supervision and operational authority over the invested funds
            of WoodmenLife. All moneys of WoodmenLife, except such amounts as
            may be necessary to pay current obligations, shall be invested by
            the committee in such investments as are, or hereafter may be,
            authorized by applicable law. The committee shall have authority to
            take any action in respect to such investments as it shall deem
            necessary or prudent and may delegate any such duties as it shall
            deem advisable.
          </WLListItem>
          <WLListItem>
            <b>Executive and Investment Committee Composition and Rules.</b>
            <WLOrderedList listStyleType="decimal">
              <WLListItem>
                <b>Composition.</b> The Executive and Investment Committees
                shall be composed of the Executive Officers of WoodmenLife.
              </WLListItem>
              <WLListItem>
                <b>Chairman and Secretary.</b> The President and Secretary of
                WoodmenLife, or designee of the Secretary, shall be,
                respectively, the Chairman and Secretary of the Executive and
                Investment Committees.
              </WLListItem>
              <WLListItem>
                <b> Meetings.</b> The Executive and Investment Committees shall
                meet at stated times or on notice to all by the Chairman thereof
                or by a majority of their own number.
              </WLListItem>
              <WLListItem>
                <b>Rules Governing Executive and Investment Committees.</b>
                <WLOrderedList listStyleType="lower-roman">
                  <WLListItem>
                    <b>Rule Making Powers.</b> The Executive and Investment
                    Committees shall have the power to make rules for the
                    conduct of their affairs and to facilitate the discharge of
                    their duties, subject to the power of the National Board of
                    Directors to alter or prescribe other or additional rules. A
                    majority of the members of any such committee shall
                    constitute a quorum, but no action of any such committee
                    shall be binding upon WoodmenLife unless taken by an
                    affirmative vote of at least a majority of the entire
                    membership of such committees.
                  </WLListItem>
                  <WLListItem>
                    <b>Executive and Investment Committees to Report.</b> The
                    Executive and Investment Committees shall report their
                    formal actions to each regular session of the National
                    Convention and the annual meeting of the National Board of
                    Directors, in writing.
                  </WLListItem>
                  <WLListItem>
                    <b>Alternates.</b> The Executive and Investment Committees
                    shall have authority, in their discretion, to designate a
                    sufficient number of alternates to serve in the absence of a
                    member or members thereof. Alternates shall have full
                    authority to act in the absence of their principal when
                    called upon to do so by the Chairman or a majority of the
                    members of the committee.
                  </WLListItem>
                </WLOrderedList>
              </WLListItem>
            </WLOrderedList>
          </WLListItem>
          <WLListItem>
            <b>Delegation of Powers.</b> The National Board of Directors may
            delegate any of the powers, authority or duties it is required to
            perform to any standing or special committee or to any officer of
            WoodmenLife upon such terms or direction as the National Board may
            determine.
          </WLListItem>
          <WLListItem>
            <b>Committee Charters.</b> Any committee, standing or special, may
            draft and adopt a charter. While any charter should be interpreted
            in the context of all applicable laws and regulations, as well as
            the context of WoodmenLife&rsquo;s Articles of Incorporation and
            this Constitution and Laws, a charter does not establish by its own
            force any legally binding obligations. In the event any provision of
            a charter conflicts with provisions of WoodmenLife&rsquo;s Articles
            of Incorporation and/or this Constitution and Laws,
            WoodmenLife&rsquo;s Articles of Incorporation and/or this
            Constitution and Laws shall govern.
          </WLListItem>
        </WLOrderedList>
      </WLText>
      <WLText>
        <b>Sec. 5. Vacancy in Directorate.</b>
        <WLOrderedList listStyleType="lower-alpha">
          <WLListItem>
            <b>
              Vacancy by a National Director other than the President &amp; CEO
              or Executive Vice President.
            </b>{' '}
            A vacancy on the National Board of Directors of any National
            Director that is not the President &amp; CEO or an Executive Vice
            President shall be filled by nomination and election by a majority
            of the National Board during any regular or special meeting thereof,
            and a successor member of the National Board of Directors shall
            serve during the unexpired term and/or until a successor is elected
            and qualified.
          </WLListItem>
          <WLListItem>
            <b>Vacancy by Executive Vice President. </b>A vacancy of an
            Executive Vice President shall be filled by the President &amp; CEO.
          </WLListItem>
        </WLOrderedList>
      </WLText>
      <WLText>
        <b>
          Sec. 6. Procedure in Event of Vacancy in Office of President &amp;
          CEO.
        </b>
        <br />
        In the event of the death, total and permanent disability, resignation,
        suspension, retirement, or removal of the President &amp; CEO, the
        Chairman of the National Board of Directors, if not the President and
        CEO, or any Executive Vice President serving on the National Board of
        Directors, in that order, shall immediately call a special meeting of
        the National Board of Directors, which shall nominate and elect by a
        majority vote a President &amp; CEO to serve during the unexpired term
        or the period of such suspension, as applicable. Until the special
        meeting of the National Board of Directors, the longest-serving
        Executive Vice President in that capacity, shall be ex officio President
        &amp; CEO.
      </WLText>
      <WLText>
        <b>
          Sec. 7. Procedure of Determining Disability, Suspension or Removal of
          Members of the National Board.
        </b>
        The National Board of Directors shall determine when any member of the
        National Board is totally and permanently disabled or should be
        suspended or removed from office. Any decision by the National Board of
        Directors that any member is totally and permanently disabled, or should
        be suspended or removed from office, shall be by an affirmative vote of
        not less than two-thirds of the total membership of the National Board.
        The removal of the President &amp; CEO or any Executive Vice President
        from the National Board of Directors under this section shall also
        result in the termination of his/her employment with WoodmenLife as
        President &amp; CEO or as an Executive Vice President.
        <br />
        In the case of the Executive Vice Presidents, in addition to removal as
        outlined above, they may also be removed by the President &amp; CEO,
        with or without cause at the sole discretion of the President &amp; CEO.
        <br />
        In the event an employee, Regional Director or Recruiting Sales Manager
        is a member of the National Board of Directors and is removed from his
        position as an employee, Regional Director or Recruiting Sales Manager,
        said employee, Regional Director or Recruiting Sales Manager is
        automatically removed from the National Board of Directors.
        <br />
        Any member of the National Board suspended or removed under this
        subsection may appeal to the Judiciary Committee and National
        Convention, as provided in Article 1, Section 2(a) and Article 8 of this
        Constitution and Laws. This right of appeal does not apply to Executive
        Vice Presidents removed by the President &amp; CEO with or without
        cause.
      </WLText>
      <WLText>
        <b>Sec. 8. Conflicts of Interest.</b>
        The National Board of Directors shall establish a Conflict of Interest
        Policy. Any National Board member violating such policy shall be
        suspended or removed as the National Board shall determine.
      </WLText>
      <WLText>
        <b>Sec. 9. Chairman of the National Board of Directors.</b>
        <WLOrderedList listStyleType="lower-alpha">
          <WLListItem>
            <b>Election of the Chairman.</b> Unless otherwise waived by an
            affirmative vote of not less than two thirds of the National Board
            of Directors, the President &amp; CEO shall automatically serve as
            the Chairman of the National Board. If so waived, the National Board
            of Directors shall then elect another member of the National Board
            of Directors to serve as the Chairman by a majority vote of the
            National Board of Directors. Should the National Board of Directors
            elect an Independent National Director as Chairman, he shall be
            considered one of the eight Independent National Directors as
            described in Article 2, Section 1 of this Constitution and Laws.
          </WLListItem>
          <WLListItem>
            <b>Preside Over National Board of Directors.</b> The Chairman shall
            preside over the meetings of the National Board of Directors. In the
            absence of the Chairman, the entire National Board of Directors
            shall elect by majority vote an interim Chairman to preside.
          </WLListItem>
          <WLListItem>
            <b>Additional Duties.</b> The Chairman shall perform such other and
            additional duties as may be assigned by the National Board of
            Directors, upon such terms and conditions as may be agreed upon.
          </WLListItem>
        </WLOrderedList>
      </WLText>
    </WLText>
  </>
);

export default Article2;
