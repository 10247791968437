import React from 'react';

import { WLText } from '../../../../design-library';

const Article8: React.FC = () => (
  <>
    <WLText>
      <b>Subordinate Bodies, Subsidiaries and Affiliated Entities. </b>The
      subordinate bodies of WoodmenLife shall be Jurisdictions, Chapters, and
      Youth Chapters. The subsidiaries of WoodmenLife shall be those entities
      established by it. The affiliated entities of WoodmenLife shall include,
      but are not limited to, fraternal, camp and chapter corporations, as well
      as not-for-profit organizations as originated by WoodmenLife.
    </WLText>
    <br />
    <WLText>
      Every adult Good-Standing Benefit Member is assigned to a chapter and can
      participate in chapter meetings and activities. Each chapter is located
      within a defined geographical area called a Jurisdiction. Chapters elect
      Jurisdictional Convention Delegates who attend Jurisdictional Conventions,
      which are held every four years. Jurisdictional Conventions elect National
      Convention Delegates who attend a National Convention, which is held every
      four years during the same year as the Jurisdictional Convention.
    </WLText>
  </>
);

export default Article8;
