import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article2: React.FC = () => (
  <>
    <WLH4>Officers, Election and Installation</WLH4>
    <WLText>
      <b>Sec. 1. Officers and Their Duties.</b>
      <br />
      The chapter shall have the following mandatory officers: President, Past
      President, Vice President, Secretary, Treasurer, and a minimum of two and
      maximum of three Auditors. The chapter may also have the following
      officers: Vice President, Community Outreach; Vice President, Youth
      Outreach; Vice President, Membership; and Vice President, Publicity.
      Officers shall be elected and serve for one year and until their
      successors are elected and qualified. The Past President shall attain
      his/her office by virtue of having previously served as President.
    </WLText>
    <WLText>
      Chapter officers, and their successors in office, shall safeguard the
      legal title to all property, real, personal and mixed belonging to the
      chapter. They shall have charge of the securities and all other evidence
      of property belonging to the chapter (and youth chapter).
    </WLText>
    <WLText>
      (a) <b>President.</b> The President shall preside at all meetings of the
      chapter; facilitate developing and implementing a plan for the chapter to
      carry out the purposes noted in Article 1, Section 3 of the Bylaws;
      approve all expenses of the chapter (and youth chapter and/or youth
      members attached to the chapter); sign all checks or warrants before
      payment is made; keep and preserve all property belonging to WoodmenLife
      and entrusted to his care; instruct all chapter officers in their duties
      (including the assigning of duties) and see that the duties are timely
      performed; and fill vacancies in an office by appointment until an
      election is held.
    </WLText>
    <WLText>
      (b) <b>Past President.</b> The Past President shall install all incoming
      officers and shall support such officers in their work toward advancing
      the common bond and mission of WoodmenLife. In the event the President and
      Vice President are unable to assume the responsibilities of the office of
      President, the Past President shall assume those duties.
    </WLText>
    <WLText>
      (c) <b>Vice President.</b> The Vice President shall assist the President
      by assuming responsibility for programs, projects, initiatives, or other
      assignments that may be made by the President and, in the absence of the
      President, shall assume his/her duties. The Vice President shall also be
      responsible for community outreach initiatives that generate new members,
      the orientation of new members and maintaining a high level of member
      participation in Chapter events and activities.
    </WLText>
    <WLText>
      (d) <b>Secretary.</b> The Secretary shall have charge of the records of
      the chapter and keep the minutes of its meetings; attend to chapter
      correspondence; serve as the contact person with the Home Office through
      the Fraternal Portal (for all electronic communications); issue all
      requests for approval of expenses incurred by the family, adult or youth
      chapter/youth division of the chapter; deliver all books and records to
      the officers of WoodmenLife and/or their representatives when called upon
      to do so; provide notification to the Treasurer as to the funds deposited
      into the proper designated account of the family, adult or youth
      chapter/youth division of the chapter by WoodmenLife; receive all other
      payments due the chapter and immediately turn them over to the Treasurer;
      make a monthly statement to the chapter of all funds received on its
      behalf; notify the Secretary of WoodmenLife immediately of all transfers
      and expulsions of members; make all reports and deliver all notices
      required by the National Board of Directors and/or the President &amp; CEO
      of WoodmenLife; and promptly notify WoodmenLife, after each election, of
      the roster of all newly elected officers.
    </WLText>
    <WLText>
      (e) <b>Treasurer.</b> The Treasurer shall verify the deposit of funds into
      the appropriate chapter account; shall receive all other chapter funds
      from the Secretary thereof; shall pay all expenses as approved by the
      Secretary and attested by the President; shall keep a separate account or
      record of the different funds received by him/her and deposit them in a
      secure depository designated by, and to the sole credit of, the chapter
      (or youth chapter); shall report to the chapter annually the activities of
      the office during the preceding year; and shall perform all other duties
      required as assigned by the President of the chapter. NOTE: The Treasurer
      shall not pay out, or contract to pay out, any sum of money, except for
      current expenses, unless there has been full compliance with the
      provisions of Article 4, Section 1.
    </WLText>
    <WLText>
      (f) <b>Auditors.</b> The Auditors shall investigate all loans and
      investments, audit all reports, books and accounts of the chapter (and
      youth chapter) at the end of every year, and make a report at the first
      meeting of the chapter in January of each year. More frequent audits may
      be conducted at the request of the chapter&rsquo;s President or Secretary
      or upon the request of the President &amp; CEO of WoodmenLife or his
      designee. The Chapter shall elect one of the Auditors as chairperson
      thereof.
    </WLText>
    <WLText>
      <b>(Optional Officers)</b>
    </WLText>
    <WLText>
      (g) <b>Vice President, Community Outreach.</b> The Vice President,
      Community Outreach shall coordinate community outreach activities and
      events as scheduled (on the chapter calendar) with the members/committees
      responsible for organizing each such activity or event. The Vice
      President, Community Outreach should also, along with the efforts of the
      President and other chapter officers, develop/maintain positive
      relationships between the chapter and various community partners. The Vice
      President, Community Outreach should participate in the annual calendar
      planning session to help set the schedule for future community outreach
      activities and events.
    </WLText>
    <WLText>
      (h) <b>Vice President, Youth Outreach.</b> The Vice President, Youth
      Outreach shall coordinate youth activities and events as scheduled (on the
      chapter calendar) with the members/committees responsible for organizing
      each such activity or event. The Vice President, Youth Outreach should
      also develop/maintain positive relationships between the chapter and
      various youth organizations. The Vice President, Youth Outreach should
      participate in the annual calendar planning session to help set the
      schedule for youth activities and events.
    </WLText>
    <WLText>
      (i) <b>Vice President, Membership.</b> The Vice President, Membership
      shall coordinate new member outreach activities and events as scheduled
      (on the chapter calendar) and encourage members to attend these new member
      activities and events (including meetings and social functions). The Vice
      President, Membership is also responsible for introducing new members to
      others from the chapter and coordinating with any and all of
      WoodmenLife&rsquo;s associates to develop referral lists and make
      introductions of prospective members.
    </WLText>
    <WLText>
      (j) <b>Vice President, Publicity.</b> The Vice President, Publicity shall
      coordinate with the Chapter President and other officers, chapter
      committees and the Home Office to publicize local chapter activities and
      events. This officer should also work closely with WoodmenLife&rsquo;s
      Sales associates to bring awareness of WoodmenLife in the community.
    </WLText>
    <WLText>
      <b>Sec. 2. Election and Installation of Officers.</b>
      <br />
      (a) Chapter officers shall be elected by a majority ballot of all members
      present during a regular or special meeting in August, September, October,
      November, or December of each year. The officers so elected or appointed
      shall be installed on or before the first meeting the following January.
      No person may serve as President, Secretary, Treasurer, or Auditor and
      another office at the same time. If an office holder accepts appointment
      or election to the office of President, Secretary, Treasurer, or Auditor,
      he/she shall automatically vacate the first office held.
    </WLText>
    <WLText>
      (b) The outgoing President shall be the installing officer or shall
      appoint another member to perform that duty or the chapter may do so. All
      chapter officers may be jointly or severally installed.
    </WLText>
    <WLText>
      (c) It shall be the duty of the Secretary of every chapter to verify that
      only qualified members are elected and installed as officers and as
      Jurisdictional Convention Delegates.
    </WLText>
    <WLText>
      (d) Whenever any officer-elect of any chapter shall not be present at the
      time set forth for installation, unless excused by a two-thirds vote of
      those present, his/her office shall be declared vacant and an election
      held to fill the vacancy. Should such officer-elect be excused, as herein
      provided, he/she shall be installed at the next regular meeting and should
      he/she fail to do so, his/her office shall be declared vacant and an
      election held to fill the vacancy.
    </WLText>
    <WLText>
      (e) If charges are proffered against the President, he/she shall stand
      suspended and the Vice President shall assume the office until the charges
      are determined by the chapter. If charges are filed against any other
      officer, the President shall appoint another member to perform the duties
      of the officer charged until the charges are determined. If determined
      adversely to such officer, the office shall stand vacated and the chapter
      shall immediately fill same by election.
    </WLText>
    <WLText>
      <b>Sec. 3. Qualifications of Officer.</b>
      <br />
      Any chapter member over the age of sixteen who is in good standing may be
      qualified as an officer of the chapter, except that no member who is,
      becomes during his/her tenure in office, or has been within the year
      immediately preceding, an officer of, an agent of, employed by,
      independently contracted with or in any other manner associated with,
      including becoming a holder of a position of trust with, another fraternal
      benefit society or life insuring institution, may serve as an officer of
      the chapter or a Jurisdictional Convention Delegate.
    </WLText>
    <WLText>
      If an officer becomes an officer of, an agent of, employed by,
      independently contracted with, or in any other manner associated with,
      including becoming a holder of a position of trust with, another fraternal
      benefit society or life insuring institution during his/her tenure in
      office, his/her position shall immediately become vacant and the President
      shall appoint an individual to fill such office until the next election.
    </WLText>
    <WLText>
      <b>Sec. 4. Consecutive Terms.</b>
      <br />
      In order to offer greater opportunity for service by all, and especially
      to new members of WoodmenLife, no elected officer other than the Secretary
      and Treasurer of the chapter shall serve more than two consecutive terms
      in the same office, unless there are special reasons for making an
      exception to the rule. Whether to make an exception shall be determined by
      a two-thirds vote of the members present after one meeting&rsquo;s written
      notice shall have been given. The exception must also be submitted to and
      approved by the President &amp; CEO of WoodmenLife or his/her designee.
    </WLText>
    <WLText>
      <b>Sec. 5. Duty to Assist All Sales Associates.</b>
      <br />
      All Chapter officers and members shall cooperate and aid authorized
      WoodmenLife Sales associates and Regional Directors in securing increased
      membership and retaining members. Members working counter to this purpose
      and/or counter to the mission and common bond of WoodmenLife shall stand
      suspended from the chapter and may not participate in any of the business
      or social affairs of the chapter, Jurisdiction or National Convention.
      &ldquo;Working counter to this purpose&rdquo; includes, but is not limited
      to, becoming an officer of, an agent of, employed by, independently
      contracted with, or in any other manner, including selling or promoting
      products of or becoming a holder of a position of trust with, another
      fraternal benefit society or life insuring institution, unless such sales
      or activity is permitted by WoodmenLife.
    </WLText>
    <WLText>
      <b>Sec. 6. Duties at End of Term of Office.</b>
      <br />
      Every Chapter officer shall, at the expiration of his/her term of office,
      deliver to the successor in office all funds, securities, property,
      records, vouchers, seal, and papers of every kind pertaining to the
      affairs of their Chapter (and/or youth chapter) or members attached
      thereto.
    </WLText>
    <WLText>
      <b>Sec. 7. Absence.</b>
      <br /> Absence of the most recent Past President or of any elective
      officer from three consecutive meetings, unless excused for reasonable
      cause by a majority vote, will be considered on the fourth or any
      subsequent meeting as equivalent to the resignation of said officer, and
      the President may order a new election to fill the vacancy. No affirmative
      action by the chapter is required prior to the calling of a new election
      by the President. Such election may be called and held during the fourth
      meeting from which the officer in question is absent.
    </WLText>
    <WLText>
      <b>Sec. 8. Removal.</b>
      <br />
      Removal from office of any chapter officer shall automatically occur when
      any officer is found to have violated any insurance statute or regulation,
      any securities regulation or any federal or state criminal law that would
      preclude employment or contracting with WoodmenLife, or who becomes, or
      has been within the year immediately preceding, an officer of, or holder
      of a position of trust with, another fraternal benefit society or life
      insuring institution. Removal from office of any chapter officer also may
      be made after one meeting&rsquo;s notice, upon the complaint of a member
      and a vote of two-thirds of the members present, provided that there shall
      be at least twelve members in attendance and the accused officer shall
      have been notified in writing by the Chapter Secretary to appear and show
      cause why he/she should not be removed. In the event the accused officer
      is the Chapter Secretary, such notice shall be given by the Chapter
      President
    </WLText>
    <WLText>
      <b>Sec. 9. Vacancies.</b>
      <br />
      The President of the chapter shall have the right to appoint an officer to
      fill a vacancy until such time as the chapter shall elect a new officer.
      Should the office of President become vacant, the Vice President shall
      assume that office and shall appoint an individual to fill the vacated
      office of Vice President.
    </WLText>
    <WLText>
      <b>Sec. 10. Compensation.</b>
      <br />
      No compensation shall be paid officers of the chapter. However, nothing in
      these Bylaws shall preclude the payment of reasonable expenses incurred in
      the legitimate conduct of Chapter business.
    </WLText>
  </>
);

export default Article2;
