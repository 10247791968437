import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article7: React.FC = () => (
  <>
    <WLH4>Dispute Resolution Procedures</WLH4>
    <WLText>
      <b>Sec. 1. Resolution of Chapter and Jurisdiction Disputes.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Purpose.</b> The purpose of this Section 1 is to identify the means
          by which members, chapters, Jurisdictions, or WoodmenLife may present
          and resolve grievances involving chapter or Jurisdiction matters or
          charges against other members, chapters, Jurisdictions or WoodmenLife
          consistent with the fraternal nature of WoodmenLife and the role of
          the fraternal lodge system, as defined by law, in fulfilling the
          purposes and objectives of WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>Scope.</b> This Section 1 shall apply whenever a member, chapter,
          Jurisdiction, or WoodmenLife has a grievance involving chapter or
          Jurisdiction matters or wishes to initiate charges against any other
          member, chapter, Jurisdiction or WoodmenLife believed to have violated
          this Constitution and Laws or the chapter or Jurisdictional Bylaws, or
          to have engaged in conduct detrimental to the best interests of the
          member, chapter, Jurisdiction, or WoodmenLife. This Section 1 shall
          not apply, and Section 2 of this Article 7 shall apply, whenever the
          matter in dispute involves a member&rsquo;s, benefit certificate
          owner&rsquo;s, beneficiary&rsquo;s or payor&rsquo;s claim for damages,
          or claim for redress for a violation of his or her individual rights
          or for a denial of individual privileges or benefits which he/she
          claims as a member, benefit certificate owner, beneficiary, or payor
          or in cases where WoodmenLife invokes the procedure against a member,
          benefit certificate owner, beneficiary, or payor regarding individual
          rights, individual privileges or benefits. This section shall not
          apply to any dispute regarding a security owned by a public customer.
        </WLListItem>
        <WLListItem>
          <b>Procedures.</b>
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              <b>Chapter Grievances and Charges Against Members.</b> Chapter
              grievances and charges against members initiated by other members
              under this Section 1 shall be filed in writing with the Chapter
              Secretary, with a copy to the President &amp; CEO of WoodmenLife.
              The Chapter Secretary shall review the grievance and/or charge and
              render a determination within thirty days of receipt of the
              grievance and/or charge. The Chapter Secretary&rsquo;s
              determination of the grievance and/or charge may be appealed in
              writing to the President &amp; CEO of WoodmenLife within thirty
              days of the Chapter Secretary&rsquo;s determination. The President
              &amp; CEO&rsquo;s decision shall be final unless the member or
              chapter files a written appeal of the decision within thirty days
              to the Judiciary Committee as provided for in Article 8 of this
              Constitution and Laws. The decision of the President &amp; CEO
              will be effective unless and until the decision is modified by the
              Judiciary Committee.
            </WLListItem>
            <WLListItem>
              <b>
                Chapter and Jurisdiction Grievances or Charges Against
                WoodmenLife.
              </b>
              Chapter and Jurisdiction grievances and charges against
              WoodmenLife under this Section 1 shall be filed in writing with
              the President &amp; CEO of WoodmenLife. The President &amp; CEO
              shall review the grievance and/or charge and render a
              determination within thirty days of receipt of the grievance or
              charge. The President &amp; CEO&rsquo;s decision will be final
              unless the chapter files a written appeal of the decision within
              thirty days to the Judiciary Committee as provided for in Article
              8 of this Constitution and Laws. The decision of the President
              &amp; CEO will be effective unless and until the decision is
              modified by the Judiciary Committee.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 2. Resolution of Individual Disputes.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Purpose. </b>The purpose of this Section 2 is to provide
          opportunities for members, benefit certificate owners, beneficiaries,
          payors, and WoodmenLife or any subordinate bodies or affiliates of
          WoodmenLife, including its former and current members of the National
          Board of Directors, officers, employees, Representatives, associates
          and agents of same, to be promptly heard and to seek fair resolution
          of any and all disputes arising from or related to the rights,
          privileges, benefits, entitlements or status that a member, benefit
          certificate owner, beneficiary, or payor may claim or enjoy from a
          benefit certificate or membership in WoodmenLife, including any
          disputes arising from or related to a member&rsquo;s involvement in
          the activities of any subordinate body or affiliate of WoodmenLife.
          Said process is consistent with the fraternal nature of WoodmenLife
          without the delay and expense of formal legal proceedings. This
          Section 2 is intended to apply to WoodmenLife or any subordinate body
          or affiliate of WoodmenLife, including its former and current members
          of the National Board of Directors, officers, employees,
          Representatives, associates and agents of same, to all current and
          future members, benefit certificate owners, beneficiaries, and payors
          and to all current and future benefit certificates.
        </WLListItem>
        <WLListItem>
          <b>Scope.</b> This Section 2 shall apply whenever a member, benefit
          certificate owner, beneficiary, or payor of WoodmenLife makes any
          claim for damages, or claims any form of redress for any alleged
          interference with, or violation of, any right, privilege, benefit, or
          interest as set forth in subsection (a) above which he or she claims
          or enjoys as a member, benefit certificate owner, beneficiary, or
          payor including, but not limited to, disputes involving alleged fraud,
          tortuous conduct, negligence, misrepresentation, breach of contract,
          discrimination, denial of civil rights, conspiracy, defamation, or
          infliction of distress caused by WoodmenLife or any subordinate body
          or affiliate of WoodmenLife or any former or current member of the
          National Board of Directors, officers, employees, Representatives,
          associates, or agents of same and disputes regarding the denial of
          benefit claims under any certificate to the extent procedures of this
          Section 2 are not prohibited by applicable law. No lawsuit may be
          filed against WoodmenLife or any former or current member of the
          National Board of Directors, officers, employees, Representatives,
          associates or agents of WoodmenLife or any subordinate body or
          affiliate of WoodmenLife regarding any dispute covered by this Section
          2 until the procedures described herein have been exhausted; a lawsuit
          may then be filed only if the applicable law does not recognize the
          procedures herein to be final and binding with respect to the matter
          in dispute. The scope of this paragraph is intended to include any and
          all claims of any nature that WoodmenLife or any subordinate body or
          affiliate of WoodmenLife might wish to invoke against a member,
          benefit certificate owner, beneficiary, or payor regarding individual
          rights, privileges, or benefits claimed by a member, benefit
          certificate owner, beneficiary, or payor. Nothing contained herein
          shall be deemed to supersede the provisions of Article 6, Section 8 of
          this Constitution and Laws.
          <br />
          This Section 2 shall specifically apply (i) in the event WoodmenLife
          or any former or current member of the National Board of Directors,
          officers, employees, Representatives, associates, or agents of same or
          any subordinate body or affiliate of WoodmenLife becomes or is made a
          party to any court or other legal proceeding to determine to whom any
          benefit of a benefit certificate shall be made payable because of
          conflicting claims, or (ii) to the payment of any benefit of a benefit
          certificate whereby WoodmenLife acknowledges that money or a benefit
          is payable but because two or more parties have submitted conflicting
          claims for the money or benefit payment, WoodmenLife is unable to
          determine to whom such benefit shall be paid. This Section 2 shall
          apply even in circumstances where WoodmenLife has not been made a
          party to any such dispute, conflicting claim, court, or other legal
          proceeding.
          <br />
          This Section 2 shall not apply to any dispute regarding a security
          owned by a public customer. Such disputes are governed by Article 7,
          Section 3 of this Constitution and Laws.
        </WLListItem>
        <WLListItem>
          <b>Initiating the Procedure.</b> A member, benefit certificate owner,
          beneficiary, payor, or WoodmenLife seeking to initiate the dispute
          resolution procedure of this Section 2 shall contact the designated
          Dispute Resolution Official of WoodmenLife, who shall assist the
          member, benefit certificate owner, beneficiary, payor, or WoodmenLife
          in requesting and arranging for the following dispute resolution
          steps:
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              Step 1. <b>Informal negotiation</b> arranged by the Dispute
              Resolution Official, involving the member, benefit certificate
              owner, beneficiary or payor, and officials of WoodmenLife as
              appropriate to the dispute.
            </WLListItem>
            <WLListItem>
              Step 2. If step 1 does not result in a mutually satisfactory
              resolution, <b>mediation</b> administered by and in accordance
              with the applicable mediation rules of the American Arbitration
              Association (or another neutral organization or party mutually
              agreed upon. If either party to the dispute elects not to
              participate in mediation, then the parties will proceed to step 3.
            </WLListItem>
            <WLListItem>
              Step 3. If step 2 does not result in a mutually satisfactory
              resolution, <b>arbitration</b> administered by and in accordance
              with the applicable arbitration rules of the American Arbitration
              Association (or another neutral organization or party mutually
              agreed upon. The arbitrator may award any and all damages, or
              other relief allowed for the claim in dispute by applicable
              federal or state law. Unless (and to the extent) prohibited by the
              applicable law with respect to the issue in dispute, the decision
              of the arbitrator shall be final and binding, subject only to the
              right to appeal such decision by applicable state and/or federal
              law. In no event shall an appeal be taken in or through the
              American Arbitration Association or under any rules providing for
              such appeals by any other neutral or neutral third party
              organization. The member, benefit certificate owner, beneficiary,
              or payor shall have the right to consult with legal counsel of his
              or her choosing at any time and, throughout steps 2 and 3, shall
              have the right to be directly represented by legal counsel, shall
              have reasonable access to and discovery of relevant information,
              and shall have reasonable notice of the dates and times of the
              mediation and/or arbitration meetings. Any costs for
              representation by legal counsel shall be borne by the member,
              benefit certificate owner, beneficiary, or payor. Every reasonable
              effort shall be made to complete step 1 within thirty days of the
              date the notice of dispute is received from the member, benefit
              certificate owner, beneficiary, or payor; step 2 within an
              additional forty-five days; and step 3 within an additional ninety
              days. These timelines are intended as a guideline only, and
              failure to complete any step within the aforementioned time
              periods will not invalidate the process or the continuation of the
              process. It is in everyone&rsquo;s best interest to raise and
              resolve disputes promptly; WoodmenLife shall not be obligated to
              process a dispute brought after the applicable statute of
              limitations period has expired. All mediation and arbitration
              meetings/hearings shall be conducted at a location convenient to
              the parties in the member&rsquo;s, benefit certificate
              owner&rsquo;s, beneficiary&rsquo;s, or payor&rsquo;s state of
              residence, unless the parties mutually agree on another location.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Rules and Procedures.</b> WoodmenLife has established rules and
          procedures for handling all matters submitted under each step in the
          Dispute Resolution Procedure. Those rules and procedures are
          incorporated by this reference and may be modified from time to time
          by WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>Restriction on Joinder of Disputes.</b> The procedures of this
          Section 2 are designed to afford individual members, benefit
          certificate owners, beneficiaries, payors, and WoodmenLife or any
          former or current member of the National Board of Directors, officers,
          employees, Representatives, associates, or agents of same, or any
          subordinate body or affiliate of WoodmenLife, a prompt, fair and
          efficient means of resolving their individual disputes. Accordingly,
          no disputes may be brought forward in a representative capacity or on
          behalf of any &ldquo;class&rdquo; of persons, and the disputes of
          multiple members, benefit certificate owners, beneficiaries, or payors
          (other than immediate family, which shall be defined as the spouse of
          any member, benefit certificate owner, beneficiary, or payor filing a
          claim and any dependent children currently residing in the same home)
          may not be joined together for purposes of these procedures without
          the express written consent of (i) all members, benefit certificate
          owners, beneficiaries, and payors affected thereby, and (ii) the
          President &amp; CEO of WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>Costs.</b> The costs incurred by the member/benefit certificate
          owner/beneficiary/payor and WoodmenLife in proceedings under this
          Section 2 are limited to the fees of mediators or arbitrators and
          filing fees, which shall be paid out of a Dispute Resolution Fund
          established by WoodmenLife. Each party shall pay its own
          attorneys&rsquo; fees and all associated costs. Because all decisions
          made by any arbitrator are final and binding on all parties,
          WoodmenLife shall not be obligated to pay any costs associated with or
          incurred by any member/benefit certificate owner/beneficiary or payor
          who attempts to appeal any final decision of any arbitrator, whether
          such attempt is made through appeal to the state or federal courts or
          through any neutral or neutral third party organization. Any and all
          such costs shall be borne solely by the member/beneficiary/certificate
          owner or payor who attempts such appeal.
        </WLListItem>
        <WLListItem>
          <b>Effect of Applicable Law.</b> In cases where a claim or dispute is
          subject to law which prohibits agreements to submit future disputes to
          binding arbitration and where such law is applicable and not preempted
          by any contrary law, then unless both the individual (member, benefit
          certificate owner, beneficiary, or payor) and WoodmenLife have
          voluntarily agreed to binding arbitration after the claim or dispute
          has arisen, step 3 of the procedure set forth above shall be
          nonbinding, and the member, benefit certificate owner, beneficiary or
          payor will be so advised. In all other cases, step 3 of the procedure
          set forth above shall be binding.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 3. Resolution of Disputes of Security Holders.</b>
      <br /> Any dispute regarding a security owned by a public and/or
      institutional customer shall be governed by the terms and conditions of
      Woodmen Financial Services, Inc.&rsquo;s Customer Agreement and
      Pre-Dispute Arbitration Agreement.
    </WLText>
  </>
);

export default Article7;
