import React from 'react';

import { WLText } from '../../../../design-library';

const Article2: React.FC = () => (
  <WLText>
    <b>Place of Business. </b>WoodmenLife’s principal office (Home Office) shall
    be in Omaha, Nebraska, or at any other place the National Board of Directors
    may select.
  </WLText>
);

export default Article2;
