import React from 'react';

import { WLText } from '../../../../design-library';

const Article3: React.FC = () => (
  <WLText>
    <b>Organization and Powers. </b>WoodmenLife is a Fraternal Benefit Society,
    organized under the laws of the State of Nebraska, without capital stock,
    conducted solely for the benefit of its members and their beneficiaries, and
    not for profit. It shall be operated on a lodge system based on local
    chapters with a ritualistic form of work and a representative form of
    government. It shall have all the powers granted by law and shall have
    perpetual existence unless these Articles of Incorporation are surrendered
    or WoodmenLife is consolidated, merged or dissolved as authorized by law.
  </WLText>
);

export default Article3;
