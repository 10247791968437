import React from 'react';

import { WLText } from '../../../../design-library';

const Article1: React.FC = () => (
  <WLText>
    <b>Name. </b>The name of this corporation is “Woodmen of the World Life
    Insurance Society,” hereinafter “WoodmenLife.”
  </WLText>
);

export default Article1;
