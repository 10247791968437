import React from 'react';

import { ConstitutionSection } from '../../index';
import Article1 from './Article1';
import Article2 from './Article2';
import Article3 from './Article3';
import Article4 from './Article4';
import Article5 from './Article5';
import Article6 from './Article6';
import Article7 from './Article7';
import Article8 from './Article8';
import Article9 from './Article9';

const ConstitutionAndLaws: ConstitutionSection = {
  title: 'Constitution & Laws',
  revised: 'January 1, 2023',
  articles: [
    {
      name: 'Article 1',
      content: <Article1 />,
    },
    {
      name: 'Article 2',
      content: <Article2 />,
    },
    {
      name: 'Article 3',
      content: <Article3 />,
    },
    {
      name: 'Article 4',
      content: <Article4 />,
    },
    {
      name: 'Article 5',
      content: <Article5 />,
    },
    {
      name: 'Article 6',
      content: <Article6 />,
    },
    {
      name: 'Article 7',
      content: <Article7 />,
    },
    {
      name: 'Article 8',
      content: <Article8 />,
    },
    {
      name: 'Article 9',
      content: <Article9 />,
    },
  ],
};

export default ConstitutionAndLaws;
