import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article6: React.FC = () => (
  <>
    <WLH4>Benefit Certificates of Membership</WLH4>
    <WLText>
      <b>Sec. 1. Benefit Certificate, Form and How Obtained.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Benefit Certificate.</b> A benefit certificate (which term includes
          a settlement option contract) creates contractual and membership
          relationships between WoodmenLife and others, as follows:
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              membership in WoodmenLife vests in the person identified as a
              member in a benefit certificate or as the payee in a settlement
              option contract or a member of a group subscribing to a benefit
              certificate; and
            </WLListItem>
            <WLListItem>
              contractual rights and privileges as set out in the benefit
              certificate and which vest in the owner of the benefit
              certificate, if different from the member.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Contracts and Waivers.</b>
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              <b>The Contract.</b> The contract between WoodmenLife and the
              owner consists of: the certificate, including any riders,
              endorsements and amendments; the application and any applications
              for modification of the certificate, which are based upon evidence
              of insurability; and the Articles of Incorporation and this
              Constitution and Laws, including all amendments to each, except
              that, where required by law, a contract on a variable basis shall
              be subject to the Articles of Incorporation and this Constitution
              and Laws in force on the date of its issue.
            </WLListItem>
            <WLListItem>
              <b>Waiver.</b> No officer, employee or member of WoodmenLife, the
              National Convention or any of its subordinate bodies, or any other
              person whatsoever, shall have the power, right or authority to
              waive any of the conditions upon which benefit certificates are
              issued, or to change or waive any of the provisions of this
              Constitution and Laws, nor shall any custom or course of dealing
              on the part of any person or entity whatsoever, with or without
              the knowledge of any officer of WoodmenLife, have the effect of so
              changing, modifying, waiving, or forgoing such laws or
              requirements. Each and every benefit certificate is issued only
              upon the conditions stated in, and subject to, this Constitution
              and Laws then in force or thereafter enacted. The knowledge or act
              of any employee or representative of WoodmenLife shall not
              constitute a waiver of the provisions hereof by WoodmenLife or an
              estoppel of WoodmenLife.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Issued in Name of WoodmenLife.</b> All such certificates shall be
          issued in the name of WoodmenLife and shall bear facsimile signatures
          of the President &amp; CEO and Secretary of WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>Assignment and Alienation of Benefit Certificate. </b>Subject to
          the notice provisions provided herein, nothing contained in this
          Constitution and Laws shall be construed to limit the right to
          assignment or alienation by a member of his or her benefit
          certificate, which shall not, of itself, terminate such membership.
          However, no assignment or pledge of a benefit certificate shall be
          effective until WoodmenLife shall have received, at
          WoodmenLife&rsquo;s Home Office, written notice of such assignment or
          pledge. WoodmenLife shall be entitled to deal only with the owner of a
          benefit certificate (whether as the original owner or any subsequent
          assignee or pledgee of the benefit certificate pursuant to an
          assignment duly received at WoodmenLife&rsquo;s Home Office), as then
          reflected on its books and records, until WoodmenLife shall have
          received, at WoodmenLife&rsquo;s Home Office, written notice of the
          assignment or pledge of a benefit certificate or written notice of
          termination of the assignment or pledge, each as the case may be.
        </WLListItem>
        <WLListItem>
          <b>Ownership/Control of Youth Certificates. </b>For certificates which
          so provide, ownership of a certificate issued in the name of a youth
          member shall be under the sole control of the applicant until the
          member reaches age sixteen; under the joint control of the applicant
          and the member between the member&rsquo;s age sixteen and the age of
          majority; and under the sole control of the member after the
          member&rsquo;s reaching the age of majority. The applicant may, by a
          writing approved by WoodmenLife, transfer the applicant&rsquo;s rights
          to any person having an insurable interest in the life of the youth
          member or to any person as allowed by applicable law. In the event of
          the death of the applicant before the youth member reaches the age of
          majority, WoodmenLife shall recognize that person who has the duty to
          support the youth member, and who in fact does support the youth
          member, as the person who is entitled to exercise the rights of
          ownership and control which the applicant could have exercised.
        </WLListItem>
        <WLListItem>
          As soon as shall be administratively practical after August 1, 2002,
          WoodmenLife shall provide certificates which allow the adult
          applicant, when applying for a certificate in the name of a youth
          member, an option to have ownership either in the applicant or in the
          youth member.
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              <b>Ownership/Control by a Youth Member.</b> If the youth member is
              the owner of the certificate, the applicant shall retain control
              over the certificate until the youth member reaches the age of
              majority. The applicant controller can exercise all rights in the
              certificate, except for the right of assignment, on behalf of the
              youth member until the youth member reaches the age of majority.
              The applicant controller may, by a writing approved by
              WoodmenLife, transfer control to any person having an insurable
              interest in the life of the youth member or to any person as
              allowed by applicable law. In the event of the death of the
              applicant controller before the youth member reaches the age of
              majority, WoodmenLife shall recognize that person who has the duty
              to support the youth member, and/or who in fact does support the
              youth member, as the person who is entitled to exercise the rights
              which the applicant controller could have exercised.
            </WLListItem>
            <WLListItem>
              <b>Ownership/Control by an Adult Applicant.</b> If the applicant
              is the owner of the certificate issued in the name of a youth
              member, the applicant shall have the right to exercise all rights
              in the certificate.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 2. Applications for Membership.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          Every application for benefit membership in WoodmenLife shall be
          signed by the applicant or a designee approved by WoodmenLife. Only
          duly authorized members of WoodmenLife&rsquo;s Sales Force shall have
          authority to solicit such applications. Each application shall be
          dated as of the date it is signed by the applicant. Youth members
          shall be admitted to WoodmenLife only upon the application of some
          adult person, under such rules, regulations and requirements as shall
          be prescribed by the President &amp; CEO or the National Board of
          Directors. &ldquo;Person&rdquo; shall be defined to include the
          trustee of a trust under which the youth member is a beneficiary,
          unless a trustee is not allowed under applicable law.
          <br />
          All provisions of this Constitution and Laws shall apply to youth
          certificates when the context does not indicate that they apply only
          to adult certificates.
        </WLListItem>
        <WLListItem>
          All applications for benefit membership must be for a benefit
          certificate underwritten by WoodmenLife.
        </WLListItem>
        <WLListItem>
          The Secretary of WoodmenLife, or designee thereof, shall assign each
          new member to the appropriate chapter or youth chapter nearest the
          member&rsquo;s home. A member shall have the right to make a written
          request to be assigned or transferred to another chapter under
          procedures established by WoodmenLife. Separate rules shall be
          established for the transfer of youth members.
        </WLListItem>
        <WLListItem>
          Benefit certificates in the form approved by the applicable regulatory
          entity and the President &amp; CEO shall be issued only upon
          application for membership as provided in this section.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 3. Designation of Beneficiaries and Changes Thereof.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Designation.</b> The beneficiary or beneficiaries, and alternate or
          alternates, if any, shall be designated in every benefit certificate.
        </WLListItem>
        <WLListItem>
          <b>Change of Beneficiary.</b> Should any person having such right
          desire to change the beneficiary or beneficiaries named in a
          certificate, he may do so, during his lifetime, by submitting an
          electronic or written request to the Home Office of WoodmenLife giving
          the name or names of the new beneficiary or beneficiaries. Upon
          receipt of a properly completed WoodmenLife form used for this purpose
          and in accordance with WoodmenLife operating procedures, when such
          change of beneficiary or beneficiaries is approved, WoodmenLife shall
          formally record the change of beneficiary and send an acknowledgement.
          Such a change of beneficiary or beneficiaries shall not be legally
          effective until such time as it has been formally adopted by
          WoodmenLife.
          <br />
          WoodmenLife shall not be required to ascertain whether or not a
          certificate is in full force and effect before complying with any such
          request, and acknowledgment of any change shall not be construed as
          validating any such certificate.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 4. Certificate Payments.</b>
      <br />
      <b>All Members Required To Make Payments</b>. All certificate payments due
      shall be made to the Home Office of WoodmenLife, on or before the first
      day of the monthly, quarterly, semiannual, or annual period it is intended
      to cover. If the reserves of WoodmenLife as to all or any class of
      certificates should become impaired, the National Board of Directors may
      require each certificate owner of WoodmenLife to make additional payments
      equal to the amount of the owner&rsquo;s equitable proportion of such
      deficiency as determined by the National Board, and if the said additional
      payment shall not be made, it shall stand as an indebtedness against the
      certificate and draw interest not to exceed five percent per year,
      compounded yearly, or alternatively the owner may consent to a reduction
      of the corresponding insurance benefit proportionate to the value of the
      additional contributions, provided there shall be no personal liability
      upon any member or upon any applicant for youth certificate for any such
      additional payments. The provisions of this paragraph shall not apply to
      contracts issued on a variable basis.
    </WLText>
    <WLText>
      <b>Sec. 5. Payment to Beneficiaries and Surviving Beneficiaries.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          In the event that two or more beneficiaries are designated as herein
          provided and one or more thereof predeceases the member, or perishes
          in the same disaster, or otherwise, so that it is not evident as to
          which died first, or if one or more of said beneficiaries are
          nonexistent or are unauthorized by the applicable law or under this
          Constitution and Laws to receive the benefits under any such
          certificate, and no new designation of beneficiary has been made by
          any person entitled to do so, that part of such benefits made payable
          to any such person shall be paid equally to the surviving beneficiary
          or beneficiaries.
        </WLListItem>
        <WLListItem>
          Should a beneficiary predecease the member, or die simultaneously with
          the member, so that it cannot be determined who died first, or if the
          member, having provided that the rights of the beneficiary shall be
          conditioned upon survivorship of the beneficiary for a specified
          period of time, and the beneficiary has failed to survive for the
          specified period of time, then the proceeds of the certificate shall
          be paid as though the beneficiary had predeceased the member.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>
        Sec. 6. Payment of Benefits When All Primary and Alternate Beneficiaries
        Are Deceased.
      </b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          In the event that the member shall be survived by no properly
          designated beneficiary to who benefits can be paid, the benefits due
          shall be paid to the surviving spouse. If there is no surviving
          spouse, then said benefits shall be paid in equal shares, to the
          surviving children and legally adopted children of the member; if
          none, then said benefits shall be paid in equal shares to the
          surviving parents of the member. If the owner of the certificate is
          different than the member and there is no provision for successor
          ownership or for reversion to the member at the owner&rsquo;s death,
          and if the member dies without a designated or valid beneficiary, or
          if benefits are payable at the owner&rsquo;s death and the owner dies
          without a designated or valid beneficiary, the benefits provided by
          the certificate shall be paid to the estate of the owner of the
          certificate.
        </WLListItem>
        <WLListItem>
          In the event there are no persons surviving the member within any of
          the above classes, designated or undesignated, then, and in that
          event, all of said benefits due under any such certificate shall be
          paid to the estate of the member.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>
        Sec. 7. Conflicts Between Certificate Provisions and Constitution and
        Laws.
      </b>
      <br />
      All provisions in benefit certificates shall prevail in the construction
      of the contractual rights thereunder when there is a real or apparent
      conflict between said provisions and this Constitution and Laws.
    </WLText>
    <WLText>
      <b>
        Sec. 8. Applicable Conditions to Certificates Regarding Costs of
        Litigation.
      </b>
      <br />
      The following shall apply to every benefit certificate: In the event
      WoodmenLife becomes or is made a party to any court proceeding to
      determine to whom any payment shall be made because of conflicting claims,
      or otherwise, to the proceeds of a benefit certificate, then all court
      costs and other necessary costs, including a reasonable fee to the
      attorney representing WoodmenLife, shall be paid out of the amount due
      under any such certificate.
    </WLText>
    <WLText>
      <b>Sec. 9. No Person to Have Any Rights Except as Herein Provided.</b>
      <br />
      No member, or any other person claiming benefits or rights under any
      benefit certificate of membership issued by WoodmenLife, shall have any
      rights in, or claim to, any funds of WoodmenLife, except as provided in
      any such benefit certificate and/or this Constitution and Laws.
    </WLText>
    <WLText>
      <b>Sec. 10. Miscellaneous Values Payable at Death.</b>
      <br /> At the death of an insured member, the benefits to be paid to the
      person or persons designated by the member, or to any person identified
      pursuant to Section 6 of this Article 6, or to any collateral assignee,
      shall include the contractual benefits provided by the certificate, plus
      any funds held by WoodmenLife in an advance premium deposit fund, and any
      other funds which WoodmenLife shall determine to be payable in respect to
      or incidental to the certificate at the member&rsquo;s death.
    </WLText>
    <WLText>
      <b>Sec. 11. Total and Permanent Disability at Age Seventy.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          When any member of WoodmenLife in good standing who holds a combined
          benefit certificate shall have reached the age of seventy years, and
          shall have become presumably permanently totally physically disabled
          by reason of old age, and who has made provision for same by
          contributing at the rate therefor, as set forth in Section 12 of this
          Article 6, on application therefor and satisfactory proof thereof
          being furnished, on blanks provided therefor, to the Secretary of
          WoodmenLife at its Home Office, there shall be paid to said member
          from the beneficiary fund, less any indebtedness standing against the
          certificate, an amount equal to ten percent of that which would be due
          under the provisions of this certificate if he/she were deceased and
          at the end of each year thereafter, if he/she shall be presumably
          permanently totally physically disabled by reason of old age, upon
          making application therefor (on blanks provided for that purpose)
          during his/her lifetime a like amount shall be paid to him/her, less
          any indebtedness standing against his/her certificate, for nine
          consecutive years. In the event he/she dies before he/she shall have
          received the ten payments above provided, the amount remaining unpaid
          on his certificate, less any indebtedness standing against the
          certificate, shall be paid to his/her beneficiary, provided no sum
          shall be paid to said member unless he/she executes a release to
          WoodmenLife for the portion thereof so paid.
          <br />
          When any such member shall have become presumably permanently totally
          physically disabled by reason of old age, WoodmenLife may make a cash
          settlement with such member in lieu of the payment of the annual
          installments above mentioned.
        </WLListItem>
        <WLListItem>
          The payment of any installment or installments on a certificate by
          WoodmenLife to the owner of said certificate does not bind WoodmenLife
          for further payments unless the member has continued to be presumably
          totally disabled and has continued the payments of the rates
          prescribed in Section 12 of this Article 6.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 12. Plan of Apportionment and Readjustment of WoodmenLife.</b>
      <br />
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          WoodmenLife&rsquo;s plan of apportionment and readjustment identified
          by the signatures of W.A. Fraser, Sovereign Commander, John T. Yates,
          Sovereign Clerk, and attested by the Seal of WoodmenLife and marked
          &ldquo;Filed July 23, 1919, John T. Yates, Sovereign Clerk &rdquo; and
          now on file in the office of the Secretary of WoodmenLife, the tables
          of rates as adopted by the Sovereign Camp at Atlanta, Georgia, at its
          1917 session and at Chicago, Illinois, at its 1919 session, and the
          provisions of Section 60 of WoodmenLife&rsquo;s Constitution, Laws and
          Bylaws, as enacted at Chicago, Illinois, July, 1919, subsequently
          amended and reenacted at the Sovereign Camp held at San Francisco,
          California, June-July, 1941, and which has appeared in each edition of
          WoodmenLife&rsquo;s Constitution, Laws and Bylaws, to and including
          that of the 27th Session of the Sovereign Camp held at San Antonio,
          Texas, in June 1949, said Section 60 having been renumbered from
          session to session until it became Section 108 at the 26th Session of
          the Sovereign Camp held in October 1947, are all made a part of these
          Laws, by reference, as fully as though the said plan of apportionment
          and readjustment, the said tables of rates and the said original
          Section 60 of the Constitution, Laws and Bylaws, as amended, were
          expressly rewritten, incorporated and included herein.
        </WLListItem>
        <WLListItem>
          Members to whom Universal Whole Life Certificates were issued on and
          after December 31, 1919, shall pay the rates per $1,000 of benefits at
          age of entry as set forth in the tables of rates as adopted by the
          Sovereign Camp at Chicago, Illinois, July, 1919, and on file in the
          office of the Secretary, and as last published in the Constitution,
          Laws and Bylaws of WoodmenLife, as amended and reenacted in June-July
          1941 at San Francisco, California.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>
        Sec. 13. Modifying the Terms of a Life Insurance Certificate After
        Issue.
      </b>
      <br />
      Modifying the terms of a life insurance certificate after issue by adding
      or removing a rider or endorsement, increasing or decreasing the death
      benefit, changing the death benefit option, reinstating the certificate
      after lapse or changing the rating class is permitted by WoodmenLife,
      except as limited by the certificate provisions and subject to
      WoodmenLife&rsquo;s underwriting requirements and administrative
      practices.
    </WLText>
  </>
);

export default Article6;
