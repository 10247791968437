import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article1: React.FC = () => (
  <>
    <WLH4>The National Convention</WLH4>
    <WLText>
      <b>Sec. 1. Name.</b>
      The supreme legislative and governing body of WoodmenLife shall be known
      as the National Convention.
    </WLText>
    <WLText>
      <b>Sec. 2. Powers.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Jurisdiction and Powers. </b>
          The National Convention shall have plenary, original and appellate
          jurisdiction in all matters pertaining to WoodmenLife and its general
          welfare. It shall have the power to enact laws for its own government,
          the government and management of WoodmenLife in general, and its
          subordinate bodies and subsidiaries. It shall be the sole judge of its
          elections and the qualification of its own National Convention
          Officers, National Convention Representatives and members of
          WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>
            Power To Suspend or Remove Its National Convention Representatives
            and Members of WoodmenLife.{' '}
          </b>
          It may, by itself when in session, or through the National Board of
          Directors when the National Convention is not in session, by a two
          thirds vote of either body, suspend or remove any of its National
          Convention Representatives or members of WoodmenLife for violating any
          of the laws of WoodmenLife or for doing any other act or thing which
          tends to bring reproach upon the National Convention, WoodmenLife or
          any of its National Convention Representatives or members of
          WoodmenLife or for any other good and sufficient cause. In addition to
          this provision, all National Convention Officers or the National Board
          of Directors when the National Convention is not in session may be
          removed as outlined in Article 2, Section 7 of this Constitution and
          Laws. Any National Convention Representatives or member of WoodmenLife
          suspended or removed under this subsection may appeal to the Judiciary
          Committee and National Convention as provided in Article 1, Section
          2(a) and Article 8 of this Constitution and Laws.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 3. Composition.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>National Convention Representatives. </b>
          The National Convention shall be composed of the National Convention
          Representatives. The National Convention Representatives include the
          National Convention Officers, the members of the Judiciary and
          Legislative Committees, Jurisdictional Presidents, Immediate Past
          Jurisdictional Presidents and duly elected National Convention
          Delegates from each Jurisdiction. Each National Convention
          Representative is entitled to one vote on any issue properly before
          the National Convention for vote.
        </WLListItem>
        <WLListItem>
          <b>National Convention Officers. </b>
          The National Convention Officers shall be the same persons as comprise
          the National Board of Directors of WoodmenLife.
        </WLListItem>
        <WLListItem>
          <b>National Convention Delegates. </b>
          The National Convention Delegates consist of the duly elected
          individuals from certain Jurisdictions as outlined and identified in
          Article 4, Section 4 of this Constitution and Laws.
        </WLListItem>
        <WLListItem>
          <b>Alternate National Convention Representatives. </b>
          In case a Jurisdictional President, an Immediate Past Jurisdictional
          President, or a National Convention Delegate from any Jurisdiction is
          unable to attend the National Convention, then the Jurisdictional Vice
          President, the Past Jurisdictional President most recently elected or
          an alternate National Convention Delegate from such Jurisdiction,
          respectively, if otherwise qualified, shall be certified by the
          Credentials Committee as a National Convention Representative of that
          session of the National Convention with all the powers and privileges
          of his/her principal, provided that notice requesting the substitution
          is received in the Home Office at least fourteen days in advance of
          the opening date of the National Convention. No person shall be
          certified as an alternate National Convention Representative except as
          herein provided.
        </WLListItem>
        <WLListItem>
          <b>Suspensions. </b>
          Any National Convention Representative, or an alternate thereto, who
          is not a Good Standing Benefit Member shall, thereby, forfeit all
          rights and privileges of such office, and such office shall be
          declared vacant by the President & CEO of WoodmenLife.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 4. Officers of the National Convention. </b>
      The officers of the National Convention shall be the same persons as
      comprise the National Board of Directors of WoodmenLife when the National
      Convention is not in session.
    </WLText>
    <WLText>
      <b>Sec. 5. Sessions and Quorum of the National Convention.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Regular Sessions. </b>
          Regular sessions of the National Convention shall be held every four
          years in the odd-numbered years beginning in 1965, at such times and
          places as may be determined by the President & CEO of WoodmenLife.
          Sessions of the National Convention may be postponed or conducted
          electronically in the event of war, national emergency, or other
          circumstances as may justify such actions as determined by the
          President & CEO of WoodmenLife, in his or her sole discretion, and
          provided that electronic sessions shall utilize a means of
          communication by which the National Convention Representatives
          participating may simultaneously hear each other during the session.
        </WLListItem>
        <WLListItem>
          <b>Special Sessions.</b>
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              Special sessions may be called by the President & CEO at any time
              and place, when authorized by two-thirds of the entire National
              Board of Directors. Special sessions shall be called and convened
              by the President & CEO, within sixty days, at such time and place
              as may be set forth in a demand therefor in writing by at least
              two-thirds of the entire National Board of Directors.
            </WLListItem>
            <WLListItem>
              Special sessions shall have all the authority and powers possessed
              by regular sessions and shall be composed of the National
              Convention Representatives who attended, or were entitled to
              attend, the last regular session of the National Convention.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Quorum. </b>A majority of the National Convention Representatives
          entitled to sit in any session of the National Convention shall
          constitute a quorum to open the National Convention. A simple majority
          of those present and voting shall be sufficient to decide all motions
          and other issues rightly before it, except as otherwise provided in
          this Constitution and Laws.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 6. National Convention Committees.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Standing Committees. </b>
          There are hereby created the following standing committees of the
          National Convention the members of which shall be appointed by the
          President & CEO on the last day of each regular session to serve until
          the close of the succeeding regular session and whose duties shall be
          as herein enumerated:
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              <b>Legislation Committee. </b>
              The Legislation Committee shall be composed of fifteen National
              Convention Representatives of the National Convention session at
              which they are appointed and who shall perform the duties set
              forth in Article 9, Section 2(c) of this Constitution and Laws.
              WoodmenLife’s General Counsel shall serve as an ex officio member
              of the committee.
            </WLListItem>
            <WLListItem>
              <b>Judiciary Committee. </b>
              The Judiciary Committee shall be composed of not fewer than five
              or more than seven National Convention Representatives. No person
              shall serve for more than two consecutive terms. The committee
              shall elect one of its members as Chairman and one as Secretary
              and notify the President & CEO and Secretary of WoodmenLife
              thereof. The General Counsel of WoodmenLife shall serve as an ex
              officio member without voting privileges.
            </WLListItem>
            <WLListItem>
              <b>Additional Standing Committees. </b>
              The President & CEO shall have authority to appoint such other
              standing committees from among the adult benefit members of
              WoodmenLife and/or the then-National Convention Representatives as
              the President & CEO shall see fit, to assist the National
              Convention in the transaction of any of its business.
            </WLListItem>
            <WLListItem>
              <b>Term of Office. </b>
              The term of office of the members of all standing committees of
              the National Convention shall be until the adjournment of the next
              regular session of the National Convention. No person may serve
              consecutive terms on the same committee unless serving by virtue
              of his or her office, except members of the Judiciary Committee
              who may serve up to two consecutive terms.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Special Committees. </b>
          Notwithstanding Article 1, Section 6(c) of this Constitution and Laws,
          at the commencement of and during each session of the National
          Convention, the President & CEO may appoint such special committees
          from among the current or past National Convention Representatives as
          he may deem necessary, to serve during that session only.
        </WLListItem>
        <WLListItem>
          <b>Nominating Committee. </b>
          The Nominating Committee is considered a Special Committee of the
          National Convention. Sixty days prior to the opening of the next
          regular session of the National Convention, the President & CEO shall
          appoint the members of a Nominating Committee, subject to approval by
          a majority vote of the National Board of Directors, who shall
          recommend a slate of National Convention Officers for approval by the
          National Convention Representatives. The terms of office of the
          members of the Nominating Committee of the National Convention shall
          be sixty days prior to the commencement of the National Convention
          until the close of the National Convention. No member of the
          Nominating Committee may serve more than two consecutive terms on the
          Nominating Committee.
        </WLListItem>
        <WLListItem>
          <b>Vacancies. </b>
          Except as otherwise provided in this Constitution and Laws, vacancies
          occurring on any of the standing or special committees of the National
          Convention, or any other committee herein authorized, may be filled by
          the President & CEO.
        </WLListItem>
        <WLListItem>
          <b>Additional Qualifications of Committee Members. </b>
          No person shall be appointed to, or remain a member of, a Special,
          Nominating or Standing Committee of the National Convention, or any
          other committee created and/or authorized by this Constitution and
          Laws, unless he/she is a Good-Standing Adult Benefit Member of
          WoodmenLife.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 7. Expenses of National Convention Representatives. </b>
      The National Convention Representatives shall be paid their reasonable
      expenses for attending the sessions of the National Convention.
    </WLText>
    <WLText>
      <b>Sec. 8. National Convention Qualification and Disqualification.</b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Qualification of National Convention Officers. </b>
          No person shall be elected by the National Convention as a National
          Convention Officer thereof, be appointed thereto, or hold such office
          unless the person is a Good Standing Benefit Member.
        </WLListItem>
        <WLListItem>
          <b>Disqualifying Occupations and Events. </b>
          Any person who violates any insurance statute or regulation or any
          federal or state criminal law that would preclude or prohibit his or
          her employment by WoodmenLife or who becomes, or has been within the
          year immediately preceding, an officer of or holder of a position of
          trust with any other fraternal benefit society or life insuring
          institution shall be ineligible to become or remain a National
          Convention Officer or to be a National Convention Delegate or a
          Jurisdictional Convention Delegate unless such person is granted a
          specific waiver to serve by WoodmenLife’s Executive Committee;
          provided, however, individuals who held a position with another
          fraternal or life insurance institution who became members of the
          Sales Force may become secretaries of Chapters and Youth Chapters of
          WoodmenLife. This shall not be construed as disqualifying any person
          who is an attorney or physician employed occasionally or temporarily
          by any such life-insuring institution and shall not disqualify a
          person employed by another society that merges with WoodmenLife if
          such persons are otherwise qualified.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>
        Sec. 9. Method of Nomination, Election, Term of Office and Installation
        of the National Convention Officers.
      </b>
      <WLOrderedList listStyleType="lower-alpha">
        <WLListItem>
          <b>Nominations. </b>
          The names of prospective National Convention Officers must be
          submitted to the President & CEO of WoodmenLife by a Good Standing
          Benefit Member of WoodmenLife sixty days prior to the National
          Convention along with an application stating each member’s
          qualifications for the office. The President & CEO shall forward each
          name and application of the prospective National Convention Officers
          to the Nominating Committee, who shall review each application and
          recommend a slate of National Convention Officers. National Convention
          Representatives may also nominate from the floor of the National
          Convention, during the designated time, a prospective National
          Convention Officer, provided such prospective National Convention
          Officer’s name was submitted to the President & CEO of WoodmenLife by
          a Good-Standing Benefit Member of WoodmenLife sixty days prior to the
          National Convention, along with an application stating the member’s
          qualifications for the office. Any floor nomination not first
          submitted to the President & CEO and Nominating Committee as set forth
          herein shall be considered out of order and void. Prior to inclusion
          of any floor nomination to the slate of official nominations to be
          voted on by the National Convention, said floor nomination(s) must
          first be sent back to the Nominating Committee to validate whether the
          nominee has appropriate credentials to serve as a National Director
          and may only be added to the formal slate of nominees upon express
          written approval of the Nominating Committee. There shall be no
          nominations made by the Nominating Committee or made from the floor
          that create contests between two nominees. All nominations must be
          submitted as a universal slate of nominees each of whom shall meet the
          requirements set forth in Article 1, Section 8(a) and Article 2,
          Section 1 of this Constitution and Laws.
        </WLListItem>
        <WLListItem>
          <b>Election.</b>
          <WLOrderedList listStyleType="decimal">
            <WLListItem>
              Except as provided otherwise, the election of all National
              Convention Officers shall be by voice vote, at such time during
              the session designated therefor in the order of business or as may
              be determined by the National Convention, and shall be decided by
              a majority vote upon a universal slate of nominees.
            </WLListItem>
            <WLListItem>
              In the event the number of nominees on the slate exceeds the
              number of open seats for office, the election of all National
              Convention Officers shall be by ballot. The nominees receiving the
              most votes shall be elected as National Convention Officers. In
              cases where a ballot is used, a committee consisting of
              WoodmenLife’s Vice Presidents (excluding the General Counsel)
              shall serve to distribute and certify the results to the National
              Convention.
            </WLListItem>
            <WLListItem>No vote may be cast by proxy.</WLListItem>
            <WLListItem>
              No election shall decide a contest between two nominees.
            </WLListItem>
            <WLListItem>
              No motion, except to take a necessary recess, shall be entertained
              during the election.
            </WLListItem>
          </WLOrderedList>
        </WLListItem>
        <WLListItem>
          <b>Term of Office. </b>
          The election of the National Convention Officers shall be held at each
          regular session of the National Convention. A National Convention
          Officer shall serve until his successor is qualified and elected as
          discussed herein.
        </WLListItem>
        <WLListItem>
          <b>Installation of National Convention Officers. </b>
          The National Convention Officers of the National Convention shall be
          installed by the President & CEO or by anyone he/she may designate.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      <b>Sec. 10. Order of Business. </b>
      At all sessions of the National Convention, business shall be taken up in
      the order established by the President & CEO. The order of business may be
      transposed by the presiding officer at any time, as occasion may require.
    </WLText>
    <WLText>
      <b>Sec. 11. Recording and Publishing Proceedings. </b>
      The Secretary, or a designee thereof, shall record all the proceedings of
      the National Convention. A synopsis of the proceedings of the National
      Convention, including all affirmative actions, thereof, shall be published
      in the official publication of WoodmenLife.
    </WLText>
  </>
);

export default Article1;
