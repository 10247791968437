import React from 'react';

import { WLH4, WLText } from '../../../../design-library';

const Article3: React.FC = () => (
  <>
    <WLH4>Other Duties and Powers</WLH4>
    <WLText>
      <b>Sec. 1. Executive Officers.</b>
      <br />
      The President shall be the Chief Executive Officer (CEO) of WoodmenLife
      and shall report to the National Board of Directors. The additional
      Executive Officers shall be the three Executive Vice Presidents serving on
      the National Board of Directors pursuant to Article 2, Section 1 of this
      Constitution and Laws. The National Board of Directors may approve
      additional Executive Vice Presidents, for appointment by the President
      &amp; CEO, as may be deemed necessary to manage WoodmenLife. The Executive
      Vice Presidents shall report to the President &amp; CEO.
    </WLText>
    <WLText>
      The specific duties of the WoodmenLife President and CEO and the Secretary
      shall be as described in this Constitution and Laws, in the attached
      Duties of Officers and as assigned. In the case of the President &amp;
      CEO, the National Board of Directors shall assign additional duties and
      responsibilities; and in the case of the other Executive Officers, the
      President &amp; CEO shall assign all other additional duties and
      responsibilities.
    </WLText>
    <WLText>
      <b>Sec. 2. Bonds of Officers and Employees; Fidelity Bond.</b>
      <br /> The National Board of Directors and/or the President &amp; CEO
      shall procure and maintain in force surety bonds on such officers and
      employees of WoodmenLife in such form and at least in such amount as
      required and specified by the Nebraska Department of Insurance.
    </WLText>
    <WLText>
      <b>Sec. 3. Execution of Contracts. </b>
      <br /> The President &amp; CEO and any one of the remaining Executive
      Officers or, in the absence of the President &amp; CEO, any two of the
      remaining Executive Officers of WoodmenLife shall have full power and
      authority to execute all contracts, documents and obligations which shall
      be necessary or desirable for the conduct of WoodmenLife&rsquo;s affairs
      when duly authorized to do so. The President &amp; CEO shall have the
      power to delegate the signing of contracts as outlined in Section 1(r)
      under Duties of Officers. In addition, the National Board of Directors or
      the Executive Committee may authorize other officers of WoodmenLife to
      execute documents which affect their areas of operation, subject to such
      directions and limitations as the National Board or Executive Committee
      may establish.
    </WLText>
    <WLText>
      <b>Sec. 4. Indemnification of Officers and Employees.</b>
      <br /> Every current and former officer, employee, and National Director
      of WoodmenLife shall be indemnified against losses or judgments assessed
      against him/her by a court of competent jurisdiction and for expenses
      actually and reasonably incurred by him/her in connection with the defense
      of any action, suit or proceeding, civil in nature, in which he/she is
      made a party by reason of serving WoodmenLife, except in relation to
      matters as to which he/she shall be adjudged in such action, suit or
      proceeding to be guilty of fraud, gross negligence or malfeasance in the
      performance of duty.
    </WLText>
  </>
);

export default Article3;
