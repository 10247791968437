import React from 'react';

import { WLText } from '../../../../design-library';

const Content: React.FC = () => (
  <>
    <WLText>
      <b>Sec. 1.</b>
      <br />
      When the President takes the Chair, the officers and members take their
      respective seats.
    </WLText>
    <WLText>
      <b>Sec. 2.</b>
      <br />
      No member shall interrupt another while speaking unless to raise a point
      of order, and while speaking no member shall pass between him/her and the
      President.
    </WLText>
    <WLText>
      <b>Sec. 3.</b>
      <br />
      Every member who desires to speak shall arise and respectfully address the
      President and, while speaking, shall confine himself/herself to the
      question under consideration and avoid all personalities, indecorous and
      sarcastic language or reflection on the subordinate body, or its members.
    </WLText>
    <WLText>
      <b>Sec. 4.</b>
      <br />
      If two or more members arise to speak at the same time, the President
      shall decide who is entitled to the floor.
    </WLText>
    <WLText>
      <b>Sec. 5.</b>
      <br />
      No member shall speak more than once on the same subject until all members
      desiring to speak shall have had an opportunity to do so and not more than
      twice without permission from the President.
    </WLText>
    <WLText>
      <b>Sec. 6.</b>
      <br />
      If a member, while speaking, be called to order by the President, he/she
      shall cease speaking and take his (her) seat until the question of order
      has been determined and permission is given him/her by the President to
      proceed.
    </WLText>
    <WLText>
      <b>Sec. 7.</b>
      <br />
      No motion shall be subject to debate until it has been seconded and stated
      by the President, and it shall be reduced to writing if requested by any
      member.
    </WLText>
    <WLText>
      <b>Sec. 8.</b>
      <br />
      Any member may call for a division of a question, which shall be divided
      if it embraces two or more distinct propositions.
    </WLText>
    <WLText>
      <b>Sec. 9.</b>
      <br />
      When a question is before the subordinate body, no other motion shall be
      entertained except to move the previous question, to lay on the table, to
      postpone indefinitely, to postpone to a certain time, to recommit or to
      amend, which motions shall severally have precedence in the order named.
    </WLText>
    <WLText>
      <b>Sec. 10.</b>
      <br />
      When a question is postponed indefinitely, it shall not be acted on again
      during the session.
    </WLText>
    <WLText>
      <b>Sec. 11.</b>
      <br />
      On the call of five members, the subordinate body may demand that the
      previous question shall be put, and until it is decided, further
      amendments to the main question and debate thereon shall be precluded.
    </WLText>
    <WLText>
      <b>Sec. 12.</b>
      <br />
      The previous question shall be put in this form: &ldquo;Shall the main
      question be now put?&rdquo;
    </WLText>
    <WLText>
      <b>Sec. 13.</b>
      <br />
      When a blank is to be filled and different sums, numbers or times shall be
      proposed, the question shall first be taken on the highest sum or number
      and on the longest or latest time.
    </WLText>
    <WLText>
      <b>Sec. 14.</b>
      <br />
      Every member present shall, if requested, vote on every question before
      the subordinate body unless for special reason the subordinate body shall
      excuse him/her.
    </WLText>
    <WLText>
      <b>Sec. 15.</b>
      <br />
      No motion for reconsideration shall be entertained unless moved by a
      member who voted with the majority in the first instance and be made
      within two meetings after taking the vote on the motion.
    </WLText>
    <WLText>
      <b>Sec. 16.</b>
      <br />
      The member first named on a committee shall act as chairman thereof,
      unless one shall be agreed on by the committee.
    </WLText>
    <WLText>
      <b>Sec. 17.</b>
      <br />
      The consequences of a measure may be attacked in strong terms, but the
      good faith of those who propose or advocate it shall not be challenged.
    </WLText>
    <WLText>
      <b>Sec. 18.</b>
      <br />
      While the President is putting a question or addressing the subordinate
      body, no member shall walk about or leave the hall or engage in
      conversation.
    </WLText>
    <WLText>
      <b>Sec. 19. </b>
      <br />
      No motion can be made by a member while another is speaking, and no motion
      can be made without the member&rsquo;s arising and addressing the Chair.
    </WLText>
    <WLText>
      <b>Sec. 20.</b>
      <br />
      No member shall arise to speak until the one occupying the floor shall
      have taken his/her) seat, and if the member wishing to follow has spoken
      on the subject twice, he/she shall not be recognized by the President
      until others have had an opportunity to speak.
    </WLText>
    <WLText>
      <b>Sec. 21.</b>
      <br />
      No member shall commence speaking on a question until recognized by the
      President.
    </WLText>
    <WLText>
      <b>Sec. 22.</b>
      <br />
      Whenever a question shall arise in the subordinate body as to the
      construction of the laws, it shall be referred to the President &amp; CEO
      of WoodmenLife, whose decision shall be entered on the minutes and be
      final.
    </WLText>
    <WLText>
      <b>Sec. 23.</b>
      <br />
      In all matters not expressly covered above, any questions of order shall
      be resolved as provided by the most currently available edition of{' '}
      <em>Robert&rsquo;s Rules of Order</em>.
    </WLText>
  </>
);

export default Content;
