import React from 'react';

import { ConstitutionSection } from '../../index';
import Content from './Content';

const RulesOfOrder: ConstitutionSection = {
  title: 'Duties of Officers',
  revised: 'January 1, 2023',
  content: <Content />,
};

export default RulesOfOrder;
