import React from 'react';

import { WLText } from '../../../../design-library';

const Article6: React.FC = () => (
  <>
    <WLText>
      <b>Amendments.</b>
      <br /> These Bylaws may be amended from time to time by the National
      Convention or by the National Board of Directors of WoodmenLife.
    </WLText>
  </>
);

export default Article6;
