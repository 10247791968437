import React from 'react';

import {
  WLH4,
  WLListItem,
  WLOrderedList,
  WLText,
} from '../../../../design-library';

const Article4: React.FC = () => (
  <>
    <WLH4>Equal Access, Meetings and Quorum Requirements</WLH4>
    <WLText>
      <b>Sec. 1. Equal Access.</b>
      <br />
      The Jurisdiction shall implement and enforce WoodmenLife&rsquo;s Equal
      Access Policy, which states: WoodmenLife is an equal access fraternal
      benefit society. It is the policy of WoodmenLife to seek qualified members
      on a nondiscriminatory basis and to provide all members with equal access
      to and allow their participation in WoodmenLife&rsquo;s chapter system,
      chapter events, customer benefits and all other fraternal activities on a
      nondiscriminatory basis.
    </WLText>
    <WLText>
      <b>Sec. 2. Sessions and Quorums of Jurisdictional Conventions.</b>
      <br />
      (a) <b>Regular Sessions.</b> Regular sessions of Jurisdictional
      Conventions shall be held quadrennially. These regular sessions shall be
      held at such places within the geographic area of the Jurisdiction as the
      Jurisdictional Convention Officers shall determine, on such dates as the
      President &amp; CEO of WoodmenLife shall approve, which shall not be
      earlier than the first Tuesday in March or later than the fourth Tuesday
      in May of the Jurisdictional meeting year. The Secretary of the
      Jurisdiction shall notify the Secretary of WoodmenLife of the place chosen
      for the next session of their Jurisdictional Convention not later than
      November 30 preceding any session thereof. Sessions of the Jurisdictional
      Convention may be postponed or conducted electronically in the event of
      war, national emergency, or other circumstances as may justify such
      actions as determined by the President &amp; CEO of WoodmenLife, in his or
      her sole discretion, and provided that electronic sessions shall utilize a
      means of communication by which all Jurisdictional Convention
      Representatives participating may simultaneously hear each other during
      the session.
    </WLText>
    <WLText>
      (b) <b>Special Sessions.</b>
      <WLOrderedList listStyleType="decimal">
        <WLListItem>
          Special sessions of the Jurisdictional Convention may be called by the
          President of the Jurisdiction, at such times and places as he/she
          shall determine, whenever the President deems it necessary for the
          good of the Jurisdiction and shall be called whenever five
          Jurisdictional Convention Officers, in writing, request the same.
        </WLListItem>
        <WLListItem>
          Special sessions shall have all the authority and powers possessed by
          regular sessions and shall be composed of the Jurisdictional
          Convention Representatives who attended, or were entitled to attend,
          the last regular session of the Jurisdictional Convention.
        </WLListItem>
      </WLOrderedList>
    </WLText>
    <WLText>
      (c) <b>Quorum.</b> A majority of the Jurisdictional Convention
      Representatives entitled to sit in any session of any Jurisdictional
      Convention shall constitute a quorum for the transaction of any business
      rightfully presented for action. A fewer number may adjourn to another
      date. A majority of any committee of any Jurisdictional Convention shall
      constitute a quorum for the transaction of any business rightfully
      presented for action. A fewer number may adjourn to another date.
    </WLText>
    <WLText>
      (d) <b>Order of Business.</b> The order of business at each regular or
      special session shall be determined by the Jurisdictional President and
      the Jurisdictional Convention Officers.
    </WLText>
    <WLText>
      <b>Sec. 3. Meetings and Quorums of Jurisdictional Convention Officers.</b>
      <br />
      (a) <b>Regular.</b> The Jurisdictional Convention Officers may hold
      regular meetings on such dates and at such places as may be determined by
      them.
      <WLText>
        <WLText>
          (b) <b>Special.</b> Special meetings of the Jurisdictional Convention
          Officers may be called by the President of the Jurisdiction, at such
          times and places as he/she shall determine, whenever the President
          deems it necessary for the good of the Jurisdiction and shall be
          called whenever five Jurisdictional Convention Officers, in writing,
          request the same.
        </WLText>
        <WLText>
          (c) <b>Quorum.</b> A majority of the Jurisdictional Convention
          Officers shall constitute a quorum at all meetings, but the
          affirmative vote of a majority of the Jurisdictional Convention
          Representatives qualified to vote on any matter shall be required to
          determine all issues rightfully before the Jurisdictional Convention
          Representatives. A fewer number may adjourn to another date.
        </WLText>
        <WLText>
          (d) <b>Order of Business.</b> The order of business at each regular or
          special meeting shall be determined by the Jurisdictional President
          and the Jurisdictional Convention Officers.
        </WLText>
        <WLText>
          (e) <b>Conduct.</b> Any meeting, regular or special, of the
          Jurisdictional Convention Officers may be conducted by telephone
          and/or video conference at the discretion of the President of the
          Jurisdiction. Furthermore, in the event a Jurisdictional Convention
          Officer is unable to appear in person at any meeting, regular or
          special, of the Jurisdictional Convention Officers, said Officer may
          appear via telephone and/or video conference at the discretion of the
          President of the Jurisdiction.
        </WLText>
        <WLText>
          <b>Sec. 4. Prerogatives of the National Convention Officers.</b>
          <br />
          (a) National Convention Officers shall be admitted to all sessions of
          the Jurisdictional meetings, with full authority to report and discuss
          any matter affecting WoodmenLife, but they shall have no voting
          rights.
        </WLText>
        <WLText>
          (b) Should the President or any other Jurisdiction Convention
          Representative proceed contrary to the laws of WoodmenLife, the
          highest ranking National Convention Officer present shall have
          authority to assume the position and authority of the Jurisdictional
          President.
        </WLText>
        <WLText>
          (c) Should any Jurisdictional Convention Representative refuse to
          allow or prevent said National Convention Officer from assuming the
          position and authority of the Jurisdictional President, said
          Jurisdiction shall be denied representation in the National
          Convention.
        </WLText>
      </WLText>
    </WLText>
  </>
);

export default Article4;
