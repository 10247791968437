import React from 'react';

import { WLText } from '../../../../design-library';

const Content: React.FC = () => (
  <>
    <WLText>
      The territory in which WoodmenLife is authorized to do business shall be
      divided into units known as Jurisdictions and the same shall be as
      follows:
    </WLText>
    <WLText>
      (1) Jurisdiction of Alabama, composed of the State of Alabama.
    </WLText>
    <WLText>
      (2) Jurisdiction of Arkansas, composed of the State of Arkansas and the
      counties of Boone, Cass, Clay, Greene, Howell, Jackson, Livingston, and
      Platte, Missouri.
    </WLText>
    <WLText>
      (3) Jurisdiction of the Pacific, composed of the States of California,
      Hawaii, Nevada, Washington, Oregon, Idaho, Utah, Wyoming, Alaska, and
      Montana.
    </WLText>
    <WLText>
      (4) Jurisdiction of Florida, composed of the State of Florida.
    </WLText>
    <WLText>
      (5) Jurisdiction of Georgia, composed of the State of Georgia.
    </WLText>
    <WLText>
      (6) Jurisdiction of the Great Lakes, composed of the States of Illinois,
      Indiana and Michigan and the counties of Audrain, St. Charles and St.
      Louis, Missouri.
    </WLText>
    <WLText>
      (7) Jurisdiction of Iowa, composed of the States of Iowa, Minnesota and
      Wisconsin.
    </WLText>
    <WLText>
      (8) Jurisdiction of Kentucky, composed of the State of Kentucky and the
      county of Dunklin, Missouri.
    </WLText>
    <WLText>
      (9) Jurisdiction of Louisiana, composed of the State of Louisiana.
    </WLText>
    <WLText>
      (10) Jurisdiction of the Mid-Atlantic, composed of the States of Delaware,
      Maryland and the District of Columbia.
    </WLText>
    <WLText>
      (11) Jurisdiction of Mississippi, composed of the State of Mississippi.
    </WLText>
    <WLText>
      (12) Jurisdiction of Nebraska, composed of the States of Nebraska, North
      Dakota, South Dakota, and Colorado.
    </WLText>
    <WLText>
      (13) Jurisdiction of New Mexico, composed of the States of New Mexico and
      Arizona and the counties of El Paso and Hudspeth, Texas.
    </WLText>
    <WLText>
      (14) Jurisdiction of the Northeast, composed of the States of New York,
      Connecticut, Massachusetts, Rhode Island, Maine, New Hampshire, New
      Jersey, and Vermont.
    </WLText>
    <WLText>
      (15) Jurisdiction of North Carolina, composed of the State of North
      Carolina.
    </WLText>
    <WLText>(16) Jurisdiction of Ohio, composed of the State of Ohio.</WLText>
    <WLText>
      (17) Jurisdiction of Oklahoma-Kansas, composed of the States of Oklahoma
      and Kansas.
    </WLText>
    <WLText>
      (18) Jurisdiction of Pennsylvania, composed of the State of Pennsylvania.
    </WLText>
    <WLText>
      (19) Jurisdiction of South Carolina, composed of the State of South
      Carolina.
    </WLText>
    <WLText>
      (20) Jurisdiction of Tennessee, composed of the State of Tennessee.
    </WLText>
    <WLText>
      (21) Jurisdiction of Texas, composed of the State of Texas except for the
      counties of El Paso and Hudspeth.
    </WLText>
    <WLText>
      (22) Jurisdiction of Virginia, composed of the State of Virginia.
    </WLText>
    <WLText>
      (23) Jurisdiction of West Virginia, composed of the State of West
      Virginia.
    </WLText>
  </>
);

export default Content;
